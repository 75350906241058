:root {
  --black: #170e0b;
  --white: white;
  --light: #feedea;
  --primary: #fba794;
  --secondary: #170e0b;
  --light-gray: lightgrey;
  --dark-gray: #636363;
  --subtitles: var(--dark-grey);
  --dark-grey: #333;
  --blue-nova: #253882;
  --blue-change: #2764e7;
  --text-color: #747474;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: var(--black);
  font-family: DM Sans, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

h1 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
}

h2 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}

h3 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
}

h4 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
}

h5 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

h6 {
  letter-spacing: -.045em;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

p {
  letter-spacing: -.045em;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 26px;
}

a {
  color: var(--black);
  text-decoration: underline;
}

ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 16px;
  line-height: 24px;
}

.section {
  background-color: var(--white);
  padding: 75px 20px;
}

.section.bg-light-blue {
  background-color: var(--light);
}

.section.bg-light-blue.none {
  background-color: var(--light);
  padding-left: 75px;
  padding-right: 40px;
}

.section.bg-light-blue.none.centered {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.section.section-hero-home {
  background-color: #9bc7f4;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.section.section-hero-template {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.section.bg-conseils {
  background-color: #e6eaee;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.section.bg-guides {
  background-color: #e6eaee;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.section.bg-faq {
  background-color: #e6eaee;
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

.section.section-simulateur {
  height: 80vh;
  padding-top: 20px;
  padding-bottom: 20px;
}

.section.section-padding-32px {
  padding-top: 32px;
  padding-bottom: 32px;
}

.section.bg-dark-blue {
  background-image: linear-gradient(to bottom, var(--black), var(--black));
  color: #fff;
}

.section.about-mobile {
  display: none;
}

.section.c-hero-section {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 45px;
  padding-bottom: 45px;
  overflow: hidden;
}

.section.c-background-light {
  background-color: #f7f4ec;
}

.section.section-hero-full {
  background-color: var(--black);
  align-items: center;
  min-height: 75vh;
  padding-top: 128px;
  padding-bottom: 128px;
  display: flex;
  position: relative;
}

.section.bg-light {
  background-color: #f8f8f4;
}

.section.hero-category-page {
  padding-bottom: 0;
}

.section.section-hero-article-blog {
  padding-top: 42px;
  padding-bottom: 0;
}

.section.article-section {
  padding-top: 50px;
  padding-bottom: 20px;
}

.container {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.container.narrow-container {
  max-width: 800px;
}

.container.container-simulateur {
  height: 100%;
}

.section-header {
  margin-bottom: 16px;
}

.section-header.large-header {
  margin-bottom: 60px;
}

.button-wrapper {
  margin-top: 32px;
  display: flex;
}

.button-wrapper.center-wrapper {
  justify-content: center;
  align-items: flex-start;
}

.button {
  border: 2px solid var(--primary);
  background-color: var(--primary);
  color: var(--black);
  letter-spacing: -.045em;
  padding: 22px 46px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: top .2s, box-shadow .2s;
  position: relative;
  top: 0;
  box-shadow: 0 0 5px #0000001a;
}

.button:hover {
  position: relative;
  top: -4px;
  box-shadow: 0 0 5px #00000040;
}

.button.button-border {
  color: var(--primary);
  background-color: #0000;
}

.button.button-border:hover {
  background-color: var(--primary);
  color: var(--white);
}

.button.button-small {
  padding: 12px 32px;
}

.button.button-green {
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
  padding: 10px 25px;
}

.button.button-footer {
  padding: 4px 24px;
  font-size: 15px;
}

.button.button-newsletter {
  border-style: none;
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
  height: 67px;
}

.button.button-call-action {
  margin-top: 0;
  padding: 4px 24px;
  font-size: 15px;
}

.button.button-white {
  border-color: var(--white);
  background-color: var(--white);
  color: var(--black);
}

.button-left {
  margin-right: 15px;
}

.nav-link {
  color: var(--black);
  letter-spacing: -.045em;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: opacity .2s;
}

.nav-link:hover {
  opacity: .8;
}

.nav-link.w--current {
  color: var(--black);
}

.nav-link.button-nav-link {
  background-color: var(--black);
  color: var(--white);
  margin-left: 16px;
  margin-right: 0;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 500;
}

.nav-link.button-nav-link-yellow {
  background-color: var(--primary);
  color: var(--black);
  margin-left: 16px;
  margin-right: 0;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 500;
}

.navbar {
  z-index: 500;
  background-color: var(--white);
  justify-content: space-around;
  height: 80px;
  padding-left: 75px;
  padding-right: 75px;
  display: flex;
  position: sticky;
  top: 0;
}

.navbar.hide {
  display: none;
}

.logo {
  width: auto;
  height: auto;
  max-height: 50px;
}

.content-box {
  width: 100%;
  max-width: 634px;
  position: relative;
}

.content-box.super-small-box {
  max-width: 280px;
}

.content-box.small-box {
  max-width: 420px;
  position: relative;
}

.content-box.large-box {
  max-width: 800px;
}

.size-2 {
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 48px;
}

.nav-list {
  align-items: center;
  display: flex;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.align-content-center {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.photo-background {
  color: var(--white);
  position: relative;
}

.logo-image {
  object-fit: contain;
  width: 128px;
  height: 128px;
  transition: top .2s;
  position: relative;
  top: 0;
}

.logo-image:hover {
  top: -4px;
}

.step-grid-home {
  grid-column-gap: 62px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 900px;
  margin-bottom: 34px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.brand-link-navigation {
  margin-right: 64px;
  padding-left: 0;
}

.nav-box {
  align-items: center;
  display: flex;
}

.decoration-element {
  position: absolute;
  inset: auto auto 0% 16px;
}

.decoration-element.element-intro {
  z-index: 0;
  inset: auto 12px -40px auto;
}

.link {
  color: var(--secondary);
  letter-spacing: -.045em;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  position: relative;
}

.paragraph-large {
  font-size: 18px;
  line-height: 30px;
}

.grid-cta {
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  background-color: var(--primary);
  grid-template-rows: auto auto;
  grid-template-columns: 1.5fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 80px;
  display: grid;
}

.paragraph-small {
  font-size: 14px;
  line-height: 24px;
}

.paragraph-small.terms-of-use {
  margin-top: 16px;
  margin-bottom: 0;
}

.subscribe-box {
  padding-left: 80px;
  position: relative;
}

.subscribe-box.centered {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  padding-left: 0;
  display: flex;
}

.subscribe-form {
  z-index: 1;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
  position: relative;
}

.field-newsletter {
  border: 1px none var(--black);
  color: #000346a6;
  letter-spacing: -.045px;
  text-transform: uppercase;
  width: 100%;
  max-width: 240px;
  height: 67px;
  margin-bottom: 0;
  padding: 24px 36px;
  font-weight: 700;
  line-height: 16px;
}

.field-newsletter.full-field {
  max-width: 320px;
  margin-bottom: 12px;
  margin-right: 0;
}

.footer {
  background-color: var(--black);
  color: #fff;
  text-align: center;
  align-items: flex-start;
  padding: 50px 75px 40px;
}

.footer-social-icon {
  width: 20px;
  height: 20px;
}

.footer-social {
  border: 2px solid #fff;
  margin-right: 16px;
  padding: 8px;
  transition: background-color .2s;
}

.footer-social:hover {
  background-color: #fff;
}

.footer-bottom-left-side {
  text-align: left;
  align-items: flex-end;
  display: flex;
}

.copyright {
  text-align: right;
  font-size: 14px;
  font-weight: 300;
}

.footer-socials {
  align-items: flex-end;
  margin-right: 8px;
  display: flex;
}

.footer-bottom {
  border-top: 2px solid #fff;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 32px;
  display: flex;
}

.hamburger-line {
  background-color: #1d1d1b;
  width: 20px;
  height: 1px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.error-message {
  border: 2px solid var(--white);
  text-align: center;
  background-color: #0000;
  margin-top: 16px;
  padding: 16px;
}

.error-message.dark-border {
  border-color: var(--black);
}

.la_search {
  align-items: center;
  width: 230px;
  display: flex;
  position: relative;
}

.search-result-item {
  max-width: 600px;
  margin-bottom: 24px;
}

.square-element {
  position: absolute;
  bottom: 27px;
  left: 29px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.password-icon {
  margin-bottom: 8px;
}

.link-navigation {
  margin-right: 24px;
  text-decoration: none;
  transition: color .35s;
}

.link-navigation:hover {
  color: var(--secondary);
  text-decoration: underline;
}

.step-wrapper {
  border: 4px solid var(--secondary);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
  display: flex;
}

.text-step {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

.grid-hero-home {
  grid-template-rows: auto;
}

.text-wrapper-hero-home {
  z-index: 0;
  background-color: var(--black);
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 32px;
  display: flex;
  position: relative;
}

.flex-align-center {
  text-align: center;
  justify-content: center;
  display: flex;
}

.flex-align-center.margin-bottom-50px {
  flex-direction: column;
}

.margin-bottom-50px {
  margin-bottom: 50px;
}

.max-width-700px {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.guide-collection-list-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.guide-collection-list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.guide-item-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.image-guide-item {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}

.logo-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}

.box-step-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 634px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.grid-footer {
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  margin-bottom: 50px;
}

.footer-link {
  color: var(--white);
  margin-bottom: 12px;
  text-decoration: none;
  transition: color .35s;
}

.footer-link:hover {
  color: var(--light-gray);
  text-decoration: underline;
}

.links-footer-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer-logo-wrapper {
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.brand-link-footer {
  margin-right: 0;
  padding-left: 0;
}

.collection-item {
  background-color: var(--white);
  transition: box-shadow .5s;
  box-shadow: 0 0 8px #00000026;
}

.collection-item:hover {
  box-shadow: 0 0 18px #0000004d;
}

.text-wrapper-guide {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  display: flex;
}

.overlay-hero {
  position: absolute;
  inset: 0%;
}

.title-wrapper-template {
  z-index: 20;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.rich-text-template {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text-template a {
  color: var(--secondary);
}

.grid-cta-simple {
  grid-column-gap: 32px;
  grid-row-gap: 0px;
  background-color: var(--primary);
  background-image: linear-gradient(to right, #0000, #fbfcffc2);
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 80px;
  display: grid;
}

.guide-collection-list-wrapper-home {
  margin-top: 0;
  margin-bottom: 50px;
}

.button-pagination {
  background-color: var(--primary);
  border-style: none;
}

.pagination {
  margin-top: 32px;
}

.legal-link {
  color: var(--white);
  text-decoration: none;
}

.legal-link:hover {
  text-decoration: underline;
}

.city-review {
  color: var(--secondary);
}

.faq-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.faq-dropdown {
  z-index: 10;
  width: 100%;
}

.faq-dropdown-list {
  width: 100%;
  margin-top: -1px;
  padding: 20px 20px 4px;
  overflow: hidden;
}

.faq-dropdown-list.w--open {
  background-color: #0000;
}

.faq-dropdown-toggle {
  cursor: pointer;
  width: 100%;
  padding: 20px 40px 20px 20px;
  transition: background-color .2s;
  position: relative;
}

.faq-dropdown-toggle:hover {
  background-color: #e6eaee;
}

.faq-dropdown-toggle.w--open {
  background-color: var(--black);
  color: var(--white);
}

.question-faq {
  white-space: normal;
  margin-right: 24px;
}

.image-bloc-home {
  height: 100%;
  padding-bottom: 100%;
  position: relative;
}

.conseils-collection-list-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.conseils-collection-list {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.dropdow-toggle-nav {
  color: var(--black);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.dropdown-list-nav.w--open {
  background-color: var(--black);
  border-radius: 8px;
  margin-top: 12px;
  padding: 8px;
}

.dropdown-link-nav {
  color: var(--white);
  border-radius: 4px;
  padding-left: 48px;
  padding-right: 48px;
}

.dropdown-link-nav:hover {
  background-color: var(--secondary);
}

.dropdown-link-nav.w--current {
  color: var(--white);
}

.grid-logos-partenaires {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.conseils-home-collection-list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-conseils {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.error-message-2 {
  background-color: #0000;
  border: 2px solid #fff;
  margin-top: 16px;
  padding: 16px;
}

.success-message {
  text-align: center;
  background-color: #0000;
  border: 2px solid #fff;
}

.success-message.dark-border {
  border-color: #000346;
}

.newsletter-wrapper {
  background-color: var(--primary);
  color: var(--black);
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  display: flex;
}

.form-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.conseils-text-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  min-width: 350px;
  max-width: 400px;
  margin-bottom: 40px;
  display: flex;
}

.icon-grid-home {
  grid-column-gap: 62px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-wall-reviews {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}

.review-wrapper-wall {
  background-color: var(--white);
  color: var(--black);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: auto;
  padding: 24px;
  display: flex;
}

.reviews-info {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-bottom: 24px;
  display: grid;
}

.face-review-wall {
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.logo-text {
  letter-spacing: 2px;
  font-family: DM Serif Display, sans-serif;
  font-size: 45px;
  line-height: 55px;
  display: inline-block;
}

.title-investis {
  width: auto;
  margin-bottom: 0;
  padding: 8px 16px;
  font-weight: 400;
}

.flex-align-center-title-review {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

.legal-newsletter-p {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}

.expert-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.logo-partenaires-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.partenaires-wrapper {
  background-color: var(--white);
  color: var(--black);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 448px;
  padding: 32px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px #0000001a;
}

.logo-partenaire {
  background-color: var(--white);
  object-fit: contain;
  border-radius: 16px;
  width: 128px;
  padding: 8px;
  transition: top .2s;
  position: relative;
  top: 0;
}

.logo-partenaire:hover {
  top: -4px;
}

.call-action-wrapper {
  z-index: 999;
  background-color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-left: 50px;
  padding-right: 50px;
  display: none;
  position: fixed;
  inset: 0% 0% auto;
  box-shadow: 0 0 8px #00000040;
}

.call-action-grid {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.navigation-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: relative;
}

.adress-text {
  margin-top: 16px;
  margin-bottom: 12px;
}

.section-header-2 {
  margin-bottom: 40px;
}

.point-about {
  color: #268a84;
}

.image-home {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.image-decoration-hero-wrapper {
  width: 100%;
  height: 25%;
  padding-bottom: 25%;
  position: relative;
}

.image-decoration-hero {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.overlay-decoration-about {
  z-index: 50;
  background-image: linear-gradient(to top, #fff, #fff0);
  height: 50%;
  position: absolute;
  inset: auto 0% 0%;
}

.content-box-hero-about {
  z-index: 100;
  width: 100%;
  max-width: 65%;
  margin-top: 32px;
  position: relative;
}

.flex-align-about {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.grid-contact {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr;
}

.image-wrapper-contact {
  height: 100%;
  padding-bottom: 100%;
  position: relative;
}

.image-contact {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.subtil-contact {
  text-transform: uppercase;
  margin-bottom: 32px;
  font-weight: 700;
}

.button-calendly {
  text-align: center;
  margin-top: 48px;
  overflow: visible;
}

.contact-text-wrapper {
  max-width: 550px;
}

.link-mobile-contact {
  margin-right: 24px;
  text-decoration: none;
  transition: color .35s;
  display: none;
}

.link-mobile-contact:hover {
  color: var(--secondary);
  text-decoration: underline;
}

.sticky-wrapper {
  z-index: 500;
  position: fixed;
  inset: auto 32px 32px auto;
}

.call-button-wrapper {
  align-items: flex-end;
  display: flex;
}

.btn-call-button {
  background-color: var(--secondary);
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  display: flex;
}

.icon-call-button {
  width: 24px;
  height: 24px;
}

.popup-call-button {
  z-index: 500;
  background-color: var(--white);
  border-radius: 16px;
  width: 500px;
  margin-right: 24px;
  padding: 24px;
  display: none;
  position: relative;
  box-shadow: 0 0 8px #0000003d;
}

.field-call {
  border: 1px solid var(--light-gray);
  color: #000346a6;
  letter-spacing: -.045px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 16px;
  padding: 24px 32px;
  font-weight: 700;
  line-height: 16px;
}

.icon-close-call-button {
  width: 18px;
  display: none;
}

.go-top-wrapper {
  background-color: var(--secondary);
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: none;
}

.icon-go-top {
  width: 24px;
  height: 24px;
}

.go-top-link {
  height: 0;
}

.line-field {
  border-style: solid;
  border-width: 0 0 2px;
  border-color: black black var(--black);
  background-color: #0000;
  height: 60px;
  margin-bottom: 16px;
  padding-left: 18px;
  font-size: 16px;
}

.line-field:focus {
  border-bottom-color: var(--black);
}

.line-field::placeholder {
  color: var(--black);
}

.area-line-field {
  border-style: solid;
  border-width: 0 0 2px;
  border-color: black black var(--black);
  color: var(--white);
  background-color: #0000;
  min-height: 120px;
  margin-bottom: 16px;
  padding-left: 18px;
  font-size: 16px;
}

.area-line-field:focus {
  border-bottom-color: var(--black);
}

.area-line-field::placeholder {
  color: var(--black);
}

.text-question-form {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

.form-question {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.btn-wrapper-faq {
  margin-top: 32px;
  display: flex;
}

.background-white {
  background-color: #fff;
  border-radius: 24px;
  padding: 16px;
}

.cookies {
  z-index: 9999;
  background-color: var(--secondary);
  color: #fff;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  max-width: 460px;
  padding: 16px;
  display: flex;
  position: fixed;
  inset: auto 24px 24px auto;
  overflow: visible;
}

.cookies-text {
  margin-bottom: 0;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;
}

.button-cookies {
  background-color: var(--primary);
  color: var(--black);
  cursor: pointer;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color .2s, color .2s;
}

.button-cookies:hover {
  opacity: .8;
}

.link-wrapper-articles {
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.breadcrumb-wrapper {
  background-color: var(--black);
  padding: 13px;
}

.link-breadcrumb {
  color: var(--white);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.icon-breadcrumb {
  width: 18px;
  height: 18px;
  margin-right: 16px;
}

.link-wrapper-breadcrumb {
  align-items: center;
  display: flex;
}

.c-button {
  color: var(--white);
  letter-spacing: 0;
  cursor: pointer;
  background-color: #401a71;
  border-radius: 16px;
  padding: 8px 32px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color .35s, box-shadow .35s;
}

.c-button:hover {
  background-color: #7e3ed1;
}

.c-heading-h1 {
  color: #222;
  letter-spacing: 1px;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

.c-heading-h2 {
  color: #222;
  letter-spacing: 1px;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
}

.c-heading-h3 {
  color: #222;
  letter-spacing: 1px;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

.c-paragraph {
  color: #222;
  letter-spacing: 1px;
}

.c-navbar {
  z-index: 500;
  background-color: var(--white);
  justify-content: space-around;
  padding: 16px 75px;
  display: flex;
  position: sticky;
  inset: 0% 0% auto;
  box-shadow: 0 0 8px -2px #00000080;
}

.c-navigation-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: relative;
}

.c-brand-link-navigation {
  padding-left: 0;
}

.c-logo {
  width: 100%;
  max-width: 80px;
}

.c-grid-hero {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1.25fr 1fr;
}

.c-text-wrapper-hero {
  z-index: 0;
  background-color: #ffffffd9;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  display: flex;
  position: relative;
}

.c-tab-link {
  color: #a28e83;
  background-color: #ddd0;
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 32px;
  transition: color .35s;
}

.c-tab-link:hover {
  color: #222;
}

.c-tab-link.w--current {
  color: #401a71;
  background-color: #ddd0;
  border-bottom: 3px solid #401a71;
  font-weight: 700;
}

.c-tab-pane {
  background-color: var(--white);
  padding: 32px;
}

.c-localisation-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-tab-content {
  margin-top: 16px;
}

.c-tabs {
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.c-tabs-menu {
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-more-info-wrapper {
  background-color: #f7f4ec;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.c-grid-more-info {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  align-items: start;
}

.c-info-wrapper {
  color: var(--white);
  background-color: #a28e83;
  padding: 32px;
}

.margin-bottom-12px {
  margin-bottom: 12px;
}

.c-grid-presentation {
  grid-column-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 32px;
}

.c-presentation-block {
  color: #222;
}

.c-subtile {
  color: #401a71;
  font-weight: 700;
}

.c-text-align-center {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-more-info-text-wrapper {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.margin-bottom-32px {
  margin-bottom: 32px;
}

.c-text-bold {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.c-text-field {
  color: #222;
  background-color: #0000;
  border: 1px #000;
  border-bottom: 1px solid #a28e83;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 26px;
}

.c-text-field:hover {
  border-bottom-color: #401a71;
}

.c-text-field:focus {
  border-bottom-width: 2px;
  border-bottom-color: #401a71;
}

.c-text-field::placeholder {
  color: #a28e83;
}

.c-form-block {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.grid-form {
  grid-column-gap: 50px;
  grid-template-rows: auto;
  margin-bottom: 16px;
}

.c-button-submit {
  color: var(--white);
  letter-spacing: 0;
  cursor: pointer;
  background-color: #401a71;
  border-radius: 16px;
  margin-top: 16px;
  padding: 8px 32px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color .35s, box-shadow .35s;
}

.c-button-submit:hover {
  background-color: #7e3ed1;
}

.c-title-wrapper-form {
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.c-success-message {
  color: var(--white);
  background-color: #ae1512;
  border-radius: 16px;
}

.c-error-message {
  color: #222;
  border-radius: 16px;
}

.c-text-field-choice {
  color: #a28e83;
  background-color: #0000;
  border: 1px #000;
  border-bottom: 1px solid #a28e83;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 26px;
}

.c-text-field-choice:hover {
  border-bottom-color: #401a71;
}

.c-text-field-choice:focus {
  border-bottom-width: 2px;
  border-bottom-color: #401a71;
}

.c-text-field-choice::placeholder {
  color: #a28e83;
}

.c-checkbox-legal {
  color: #222;
}

.c-link-legal {
  color: #222;
  cursor: pointer;
}

.max-width-450px {
  max-width: 450px;
}

.rdv-confirm-wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading-confirmation {
  font-size: 55px;
  line-height: 65px;
}

.c-p-navigation {
  color: #222;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 28px;
}

.c-logo-wrapper {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-left: 32px;
  display: flex;
}

.image {
  object-fit: cover;
  width: 90px;
}

.c-mention-text {
  color: #222;
  text-align: center;
  max-width: 750px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  line-height: 22px;
}

.c-logo-second {
  width: 100%;
  max-width: 65px;
  margin-left: 32px;
}

.c-p-hero-section {
  color: #222;
  letter-spacing: 1px;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 28px;
}

.c-button-hero-wrapper {
  align-items: center;
  display: flex;
}

.link-site-plan {
  text-decoration: none;
  transition: color .35s;
}

.link-site-plan:hover {
  color: var(--dark-gray);
  text-decoration: underline;
}

.site-plan-wrapper {
  margin-bottom: 32px;
}

.grid-download-guide {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: .75fr 1fr;
}

.download-text-wrapper {
  flex-direction: column;
  align-items: flex-start;
  max-width: 550px;
  display: flex;
}

.contact-form-download {
  color: #fff;
  background-color: #f90;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 16px;
  display: flex;
}

.conseils-download-collection-list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-download {
  align-self: flex-end;
  width: 25%;
}

.small-line-field {
  color: var(--white);
  background-color: #0000;
  border: 0 solid #000;
  border-bottom: 2px solid #fff;
  margin-bottom: 16px;
  padding-left: 14px;
  font-size: 16px;
  line-height: 26px;
}

.small-line-field::placeholder {
  color: #ececec;
}

.heading, .paragraph {
  text-align: center;
}

.grid-full-page-home {
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
}

.text-color-white {
  color: var(--white);
}

.text-wrapper-full-home {
  z-index: 0;
  background-color: var(--black);
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.navbar-dark {
  z-index: 500;
  background-color: var(--black);
  justify-content: space-around;
  height: 80px;
  padding: 16px 75px;
  display: flex;
  position: sticky;
  top: 0;
}

.link-navigation-white {
  color: var(--white);
  margin-right: 24px;
  text-decoration: none;
  transition: color .35s;
}

.link-navigation-white:hover {
  color: var(--light-gray);
  text-decoration: underline;
}

.dropdow-toggle-nav-white {
  color: var(--white);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.dropdown-list-nav-white.w--open {
  background-color: var(--white);
  border-radius: 8px;
  margin-top: 12px;
  padding: 8px;
}

.dropdown-link-nav-dark {
  color: var(--black);
  border-radius: 4px;
  padding-left: 48px;
  padding-right: 48px;
}

.dropdown-link-nav-dark:hover {
  background-color: #ebebeb;
}

.dropdown-link-nav-dark.w--current {
  color: var(--white);
}

.contact-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.contact-text-bloc {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.image-contact-rdv {
  object-fit: cover;
}

.title-hero-home {
  color: var(--white);
  font-size: 64px;
  line-height: 120%;
}

.legal-link-dark {
  color: var(--black);
  text-decoration: none;
}

.legal-link-dark:hover {
  text-decoration: underline;
}

.form {
  color: var(--black);
}

.text-underline {
  background-image: linear-gradient(180deg, transparent 85%, var(--primary) 85%, var(--primary) 95%, transparent 95%);
}

.arguments-wrapper-hero {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 500px;
  margin-top: 42px;
  display: grid;
}

.argument-card {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.argument-icon {
  object-fit: contain;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}

.argument-text {
  color: var(--white);
}

.image-step {
  object-fit: contain;
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
}

.empty-state {
  display: none;
}

.html-embed {
  z-index: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  inset: 0%;
}

.html-embed.hide {
  display: none;
}

.cover-blog-card-wrapper {
  width: 100%;
  height: 150px;
  position: relative;
}

.category-card-blog {
  background-color: var(--primary);
  color: var(--black);
  border-radius: 64px;
  margin-bottom: 15px;
  padding: 2px 8px;
  font-size: 9px;
  line-height: 15px;
}

.time-card-blog {
  color: var(--black);
  background-color: #fffc;
  border-radius: 64px;
  margin-bottom: 4px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 20px;
  position: absolute;
  inset: 6px 6px auto auto;
}

.text-cta-scroll-mobile {
  display: none;
}

.scroll-indicator-blog {
  background-color: var(--secondary);
  height: 4px;
  position: fixed;
  inset: 80px 0% auto;
}

.hero-article-blog {
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.breadcrumb-article-blog {
  flex-wrap: wrap;
  margin-bottom: 16px;
  display: flex;
}

.text-breadcrumb {
  color: #929292;
  font-size: 13px;
  line-height: 20px;
  text-decoration: none;
}

.text-breadcrumb:hover {
  text-decoration: underline;
}

.separator-breadcrumb {
  color: #929292;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  line-height: 20px;
}

.author-wrapper {
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.author-profil-image {
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.div-block {
  margin-left: 12px;
}

.job-title-author {
  color: var(--secondary);
  line-height: 18px;
}

.summary-wrapper {
  background-color: #f0f0f0;
  width: 100%;
  max-width: 700px;
  margin-bottom: 42px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.title-toc {
  font-size: 24px;
  line-height: 32px;
}

.summary-article {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.cta-in-article {
  background-color: var(--primary);
  color: var(--black);
  background-image: linear-gradient(to right, #0000, #fbfcffc2);
  grid-template-rows: auto auto;
  grid-template-columns: 1.5fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 32px 42px;
  display: flex;
}

.title-cta-article {
  margin-bottom: 0;
  margin-right: 32px;
}

.button-article {
  background-color: var(--secondary);
  color: #fff;
  text-align: center;
  padding: 20px 40px;
  text-decoration: none;
  transition: background-color .35s;
}

.button-article:hover {
  background-color: var(--black);
}

.wrapper-text-highlighted {
  background-color: #f0f0f0;
  margin-bottom: 16px;
  padding: 16px;
}

.text-highlighted {
  margin-bottom: 0;
}

.logo-cta-scroll {
  width: auto;
  height: auto;
  max-height: 50px;
}

.category-text-wrapper-hero {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section-2 {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.half-section {
  background-color: #fdfdfd;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  display: flex;
}

.half-section.image {
  background-color: #fba894;
  padding: 30px;
}

.div-block-3 {
  width: 50px;
}

.heading-2 {
  font-family: DM Sans, sans-serif;
}

.center-form {
  width: 80%;
  max-width: 400px;
}

.heading-form {
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-weight: 600;
}

.text-block {
  color: var(--secondary);
  margin-bottom: 40px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.holder-full-name {
  display: flex;
}

.text-field {
  border-bottom: 1px solid #727272;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
  font-family: Figtree, sans-serif;
  font-weight: 300;
}

.text-field.last {
  margin-right: 0;
}

.text-field.last._2 {
  margin-bottom: 25px;
}

.text-field-2 {
  background-color: #0000;
  border-radius: 5px;
  font-family: Figtree, sans-serif;
  font-weight: 300;
}

.button-retour {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 5px;
  text-decoration: none;
  transition: box-shadow .35s, border-radius .35s, background-color .35s;
  display: flex;
  position: absolute;
  inset: 5% auto auto 5%;
}

.button-retour:hover {
  background-color: #eee;
  border-radius: 5px;
}

.image-2 {
  width: 12px;
  margin-right: 5px;
}

.text-block-2 {
  color: var(--secondary);
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.submit-button {
  background-color: var(--primary);
  color: var(--black);
  text-transform: uppercase;
  background-position: -170px 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  transition: color .3s, background-position .3s cubic-bezier(.645, .045, .355, 1);
}

.submit-button:hover {
  color: var(--white);
  background-position: 0 0;
}

.image-3 {
  width: 60%;
  max-width: 350px;
}

.text-block-3 {
  color: var(--black);
  text-align: right;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  display: none;
}

.text-block-4 {
  color: var(--black);
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: none;
}

.div-block-4 {
  width: 80%;
  max-width: 350px;
  margin-bottom: 30px;
}

.center-button {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.heading-3 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 30px;
}

.paragraph-2 {
  font-size: 14px;
  line-height: 20px;
}

.paragraph-3 {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.holder-blogs {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: none;
  display: flex;
  position: relative;
}

.link-block {
  opacity: .85;
  background-color: #fff;
  border-radius: 5px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 330px;
  height: 450px;
  padding: 10px;
  text-decoration: none;
  transition: opacity .3s cubic-bezier(.47, 0, .745, .715);
  display: flex;
  position: relative;
  box-shadow: 0 -1px 4px #0003;
}

.link-block:hover {
  opacity: 1;
}

.link-block._0opcity {
  opacity: 0;
}

.image-4 {
  border-radius: 5px;
  margin-bottom: 5px;
}

.container-2 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.date {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #fff9;
  border-radius: 5px;
  width: 90px;
  height: 25px;
  display: block;
  position: absolute;
  inset: 5% 8% auto auto;
}

.text-block-5 {
  text-align: center;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.holder-alerte {
  background-color: #fba894;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.dot {
  background-color: #000;
  border-radius: 99px;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}

.text-block-6 {
  font-family: Lexend, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
}

.heading-4 {
  font-size: 20px;
  line-height: 30px;
}

.h1 {
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}

.text-block-7 {
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.lire-plus {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: auto 8% 3% auto;
}

.text-block-8 {
  color: var(--subtitles);
  text-transform: uppercase;
  margin-right: 10px;
  font-family: Figtree, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.image-5 {
  width: 15px;
}

.icon-2 {
  color: var(--dark-grey);
  font-size: 25px;
}

.slider {
  background-color: #0000;
  width: 100%;
  height: 470px;
}

.icon-3 {
  color: var(--dark-grey);
  font-size: 25px;
}

.slide-nav {
  z-index: 20;
  color: #0000;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 3px;
  font-family: Exo, sans-serif;
  font-size: 5px;
  line-height: 5px;
  display: flex;
  top: 101%;
}

._100 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.holder-image-cover {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 160px;
  margin-bottom: 5px;
  overflow: hidden;
}

.slide {
  width: 80%;
}

.slide-2 {
  width: 370px;
}

.slide-3, .slide-4, .slide-5 {
  width: 70%;
}

.container-div {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container-div.flex-verticale {
  flex: 1;
  max-width: 1100px;
}

.image-6 {
  z-index: 10;
  position: absolute;
}

.left-arrow {
  width: 15%;
}

.right-arrow {
  z-index: 11;
  width: 30%;
}

.slide-1 {
  width: 370px;
}

.slide-6 {
  width: 75%;
}

.slider-2, .slider-3 {
  width: 370px;
}

.slider-4 {
  width: 100%;
  max-width: 650px;
  height: 490px;
  overflow: hidden;
}

.slide-7 {
  width: 370px;
}

.mask {
  width: 370px;
  overflow: visible;
}

.section-3 {
  justify-content: center;
  align-items: center;
  height: 600px;
  display: none;
}

.text-content {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
}

.image-content {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.image-7 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.holder-link-pages {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.linked-pages {
  text-decoration: none;
}

.linked-pages:hover {
  text-decoration: underline;
}

.text-block-9 {
  color: var(--dark-gray);
  margin-right: 5px;
}

.text-block-10, .text-block-11 {
  color: var(--dark-gray);
  margin-right: 5px;
  font-size: 12px;
}

.heading-6 {
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 50px;
}

.text-block-12 {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 26px;
}

.holder-autor {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.image-8 {
  object-fit: cover;
  border-radius: 99px;
  width: 50px;
  height: 50px;
}

.holder-image-autor {
  border-radius: 99px;
  width: 50px;
  height: 50px;
}

.rich-text-block {
  width: 100%;
  max-width: 700px;
}

.section-4 {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

.sticky-div-holder {
  min-width: 330px;
  max-width: 360px;
  display: none;
  position: sticky;
  top: 90px;
}

.somaire-article {
  background-image: linear-gradient(135deg, #edf9ff, #0000);
  border-radius: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 30px 20px;
  display: flex;
  box-shadow: 2px 2px 1px #0003;
}

.somaire-article.hide {
  background-color: #ebebeb;
  background-image: none;
  width: 100%;
  max-width: 700px;
}

.linked-in-page {
  margin-bottom: 5px;
  text-decoration: none;
}

.linked-in-page:hover {
  text-decoration: underline;
}

.bold-text {
  font-size: 14px;
  line-height: 22px;
}

.div-ctc {
  background-color: var(--primary);
  background-image: linear-gradient(45deg, #0000, #fbfcffbf);
  border-radius: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
  box-shadow: 2px 2px 1px #0003;
}

.div-ctc.hide {
  display: none;
}

.text-block-13 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.section-5 {
  background-color: var(--light);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 75px 40px;
  display: flex;
}

.holder-blogs-post {
  grid-column-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.holder-title {
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
}

.heading-8 {
  margin-bottom: 0;
}

.somaire-article-out-cms {
  background-color: #f0f0f0;
  width: 100%;
  max-width: 700px;
  padding: 32px;
}

.link-summary {
  margin-bottom: 5px;
  text-decoration: none;
}

.link-summary:hover {
  text-decoration: underline;
}

.bold-text-2, .bold-text-3, .bold-text-4 {
  font-weight: 500;
}

.holder-links-blogs {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.la_nav-lead-access {
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

.la_nav-lead-access.preprod_version {
  padding-top: 100px;
}

.la_holder-elements-metrics {
  flex-flow: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.la_holder-elements-metrics.last {
  justify-content: flex-start;
  align-items: flex-end;
}

.text-block-16 {
  color: #24398a;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
}

.la_holder-icon-number {
  background-color: #24398a;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 20px;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 4px 2px #00000026;
}

.la_icon-metrics {
  margin-right: 15px;
}

.la_holder-emoji-number {
  background-color: #fff3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.la_number-calendar {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 35px;
  line-height: 40px;
}

.la_image-10 {
  margin-right: 10px;
}

.la_image-10.last {
  margin-right: 5px;
}

.la_image-10._1 {
  width: 40px;
  height: 40px;
  margin-right: 0;
}

.la_holder-text-tuto {
  background-color: #24398a;
  border-radius: 10px;
  width: 230px;
  padding: 10px;
  display: none;
  position: absolute;
  inset: 100% auto auto 0%;
}

.la_holder-text-tuto.last {
  display: block;
  inset: 100% 0% 0% auto;
}

.la_holder-text-tuto._2 {
  display: none;
}

.la_holder-text-tuto._3rd {
  inset: 100% 0% auto auto;
}

.la_title-tuto {
  color: var(--white);
}

.la_text-tuto {
  color: var(--white);
  font-family: Poppins, sans-serif;
  font-size: 9px;
  font-weight: 300;
  line-height: 15px;
}

.la_holder-tuto {
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 99px;
  width: 15px;
  height: 15px;
  position: absolute;
  inset: 3px auto auto 3px;
  box-shadow: inset 0 2px 4px #0003, 1px 1px #0003;
}

.la_holder-tuto.last {
  inset: 0% auto auto -25px;
}

.la_holder-tutotxt {
  color: var(--white);
  text-align: center;
  font-size: 10px;
  line-height: 15px;
}

.la_holder-emojis {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
}

.happy-emoji {
  position: absolute;
  inset: 0%;
}

.angry-emoji, .stable-emoji {
  display: none;
  position: absolute;
  inset: 0%;
}

.first-class-blog-author {
  width: 100%;
  max-width: 700px;
  margin-bottom: 40px;
}

.holder-author-blog {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.holder-name-function {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.holder-image-author {
  object-fit: cover;
  border-radius: 99px;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
}

.text-block-21 {
  color: var(--dark-grey);
  font-family: DM Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.la_nav-bar-top {
  z-index: 99;
  background-color: #24398a;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: fixed;
  inset: 0% 0% auto;
}

.la_nav-bar-top.preprod {
  margin-top: 30px;
}

.la_holder-links-pages {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.la_link-page {
  background-color: #ffffff1a;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.la_link-page:active {
  background-color: #fff3;
  box-shadow: inset 0 0 5px #fff3, 0 2px 5px #fff3;
}

.la_link-page:visited {
  box-shadow: inset 0 0 5px #fff3, 0 2px 5px #ffffff26;
}

.la_link-page.last {
  margin-right: 0;
}

.la_profile-user {
  cursor: pointer;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 17px 7px 13px;
  text-decoration: none;
  display: flex;
}

.la_profile-user:active {
  box-shadow: 0 0 5px #ffffff4d;
}

.la_name-user {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.la_user-image {
  width: 30px;
  margin-right: 10px;
}

.la_lead-stats {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 30px;
  display: flex;
}

.la_number-leads {
  font-family: Figtree, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.la_text-span {
  color: #253882;
}

.la_stats-leads {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.la_stats-model {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #d8d8d826;
  border: 1px solid #25388226;
  border-radius: 15px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 7px 10px;
  display: flex;
}

.la_stats-model._2 {
  background-color: #275dff26;
}

.la_stats-model._3 {
  background-color: #e8aa4d26;
}

.la_stats-model._4 {
  background-color: #be010426;
}

.la_stats-model._5 {
  background-color: #be010440;
}

.la_stats-model._6 {
  background-color: #0089490d;
}

.la_stats-model._7 {
  background-color: #00894926;
}

.la_stats-model._8 {
  background-color: #00894940;
}

.la_stats-model._9 {
  background-color: #4de09b40;
}

.la_stats-model._10 {
  background-color: #2538820d;
}

.la_status-title {
  color: #253882;
  margin-bottom: -10px;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.la_status-number {
  color: #253882cc;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  line-height: 15px;
}

.la_holder-metrics {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.la_holder-nav-bar-leads {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.la_nav-search-filtres {
  background-color: #253882;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 20px;
  display: flex;
}

.la_toggle-button {
  cursor: pointer;
  background-color: #e9f0fd;
  border-radius: 99px;
  flex-flow: row;
  width: 70px;
  height: 40px;
  margin-right: 15px;
  padding: 5px;
  display: flex;
}

.la_toggle-button.selected {
  flex-flow: row-reverse;
}

.la_holder-toggles {
  width: 30px;
  height: 30px;
  position: relative;
}

.la_icon-in-toggle {
  position: absolute;
  inset: 0%;
}

.la_toggle-search {
  display: flex;
}

.la_search-input {
  color: var(--white);
  background-color: #fff3;
  border: 1px solid #fff3;
  border-radius: 99px;
  width: 230px;
  height: 40px;
  margin-bottom: 0;
  padding-left: 15px;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.la_search-button {
  background-color: #0000;
  width: 40px;
  height: 40px;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.la_search-2 {
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
  display: flex;
}

.la_icon-search {
  position: absolute;
  inset: 5px 10px auto auto;
}

.la_filters {
  display: flex;
}

.filter-choice {
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
}

.horizontale-text {
  margin-right: 10px;
}

.text-block-25 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 12px;
  line-height: 15px;
}

.text-block-26 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 13px;
}

.la_form-filter {
  cursor: pointer;
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 38px;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.la_form-filter-pop-up {
  background-color: #51609b;
  border: 1px solid #ffffff4d;
  border-radius: 10px;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  inset: 45px 0% auto auto;
}

.la_holder-input {
  margin-right: 10px;
}

.la_holder-input.right {
  margin-right: 0;
}

.la_holder-input.last {
  margin-bottom: 10px;
  margin-right: 0;
}

.la_text-field-3 {
  border: 1px solid #2538824d;
  border-radius: 5px;
  width: 60px;
  height: 25px;
  margin-bottom: 0;
}

.la_text-field-3._130 {
  width: 130px;
}

.la_field-label {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
}

.la_horizontal-inputs {
  justify-content: space-between;
  align-items: center;
  width: 130px;
  display: flex;
}

.la_horizontal-inputs._1 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.la_b2b_b2c {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.la_b2b {
  cursor: pointer;
  background-color: #25388233;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 7px 33px;
  text-decoration: none;
  display: flex;
}

.la_b2b._2 {
  background-color: #25388233;
}

.la_b2b.selected {
  color: var(--white);
  background-color: #253882;
}

.la_text-b2b {
  font-size: 16px;
}

.la_text-b2b._2 {
  color: #253882;
}

.la_lead-access-holder {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 40px;
  display: flex;
}

.la_the-lead {
  background-color: #fff;
  border-radius: 10px;
  flex-flow: column;
  margin-bottom: 30px;
  display: flex;
}

.la_holder-name-and-status {
  background-color: #253882;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.la_holder-name-and-status.small {
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
}

.la_name-lead {
  color: var(--white);
  text-transform: capitalize;
  font-family: Figtree, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 33px;
}

.la_name-lead.small {
  font-size: 18px;
  line-height: 26px;
}

.status-holder {
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 8px 20px;
  text-decoration: none;
  display: flex;
}

.statut {
  color: var(--white);
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}

.la_low-partp-lead {
  padding: 20px 40px;
}

.la_low-partp-lead.small {
  padding: 5px 10px 0;
}

.la_contact-information {
  color: #24398a;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  display: none;
}

.la_holder-c_i-spaced {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
  display: flex;
}

.la_verticale-informations {
  flex-flow: column;
  width: 33%;
  display: flex;
}

.la_type-information {
  background-color: #2538821a;
  border: 1px solid #2538821a;
  border-radius: 15px;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 20px;
  display: flex;
}

.la_type-information.small {
  border-radius: 5px;
  height: 40px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.la_lad_category_text {
  color: #253882;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.la_lad_category_text.small {
  font-size: 11px;
  line-height: 20px;
}

.la_text-field-4 {
  color: #253882;
  background-color: #7894ff0d;
  border: 1px solid #25388226;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
  padding-top: 25px;
  padding-left: 20px;
  font-size: 16px;
}

.la_text-field-4.situation-familiale {
  padding-top: 19px;
  padding-left: 15px;
}

.la_text-field-4.situation-familiale.small {
  padding-top: 20px;
}

.la_text-field-4.situation-familiale.small.dropdown {
  padding-top: 17px;
  padding-left: 5px;
}

.la_text-field-4.small {
  border-radius: 5px;
  height: 40px;
  padding-top: 20px;
  padding-left: 10px;
  font-size: 10px;
  line-height: 15px;
}

.la_holder-input-info {
  position: relative;
}

.la_text-block-32 {
  color: #253882;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  inset: 7px auto auto 20px;
}

.la_text-block-32.small {
  font-size: 10px;
  top: 4px;
  left: 10px;
}

.link-block-2 {
  position: absolute;
  inset: auto 0% 0%;
}

.la_center-link-holder {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  inset: auto 0% -8px;
}

.la_plus-info {
  cursor: pointer;
  background-color: #253882;
  border: .5px solid #fff6;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.la_holder-comments-commandes {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.la_text-field-5 {
  color: #253883;
  background-color: #2538821a;
  border: 1px solid #2538821a;
  border-radius: 15px;
  height: 40px;
  margin-bottom: 0;
  padding-left: 40px;
  font-family: Figtree, sans-serif;
}

.la_form-2 {
  position: relative;
}

.la_image-14 {
  position: absolute;
  inset: 10px auto 0% 15px;
}

.la_form-block-2 {
  width: 100%;
  margin-right: 15px;
}

.la_commande-link {
  cursor: pointer;
  background-color: #253882;
  border: 1px solid #ffffff4d;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-decoration: none;
  display: flex;
}

.la_commandes {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: #0000;
  border-radius: 15px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.la-360-man-b {
  z-index: 999;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_holder-outils {
  background-color: #253882;
  border: 2px solid #ffffff4d;
  border-radius: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  font-style: normal;
  display: flex;
  position: relative;
}

.la_title-outils {
  background-color: #fff;
  border: 1px solid #25388280;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  height: 41px;
  margin-bottom: 15px;
  padding: 8px 15px;
  display: flex;
}

.la_image-15 {
  margin-right: 10px;
}

.la_text-block-33 {
  color: #253883;
  text-transform: uppercase;
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.la_holder-partners {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.la_holder-partners._2 {
  margin-bottom: 10px;
}

.la_holder-partners._0 {
  margin-bottom: 0;
}

.la_holder-partner {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 68px;
  text-decoration: none;
  transition: box-shadow .3s;
  display: flex;
  box-shadow: 0 2px #0003;
}

.la_holder-partner:hover {
  box-shadow: 0 0 3px 2px #ffffff40;
}

.la_image-16 {
  max-width: 180px;
  height: 40px;
}

.la_image-16.g {
  height: auto;
}

.la_text-block-34 {
  color: #253882;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.la_close-button {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: 20px 30px auto auto;
}

.la_close-button.left {
  inset: 20px auto auto 20px;
}

.la_close-button._2 {
  top: 10px;
  right: 20px;
}

.la_text-block-35 {
  color: var(--white);
  text-transform: uppercase;
  margin-right: 5px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.la-360-bg-b {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_browser-div {
  background-color: #253882;
  border-radius: 20px;
  justify-content: space-between;
  width: 98vw;
  height: 90vh;
  padding: 20px;
  display: flex;
  position: relative;
}

.la_holder-contact-informations {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
  padding-top: 50px;
  display: flex;
}

.la_open-navigator {
  background-color: #ffffff1a;
  border: 1px solid #ffffff26;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 220px;
  height: 40px;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.la_text-block-36 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 13px;
}

.la_image-17 {
  margin-right: 6px;
}

.la_text-block-37 {
  color: var(--white);
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.la_contact-informations {
  flex-flow: column;
  display: flex;
}

.la_text-field-6 {
  color: var(--white);
  background-color: #e9f0fd1a;
  border: 0 solid #000;
  border-radius: 5px;
  width: 220px;
  height: 50px;
  margin-bottom: 0;
  padding-top: 25px;
  padding-left: 15px;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  line-height: 15px;
}

.la_form-3 {
  position: relative;
}

.la_text-block-38 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  inset: 6px auto auto 15px;
}

.la_form-block-3 {
  margin-bottom: 10px;
}

.link-block-3 {
  position: absolute;
  inset: 20px auto auto 20px;
}

.la_image-18 {
  margin-right: 5px;
}

.la_text-block-39 {
  color: var(--white);
  font-family: Figtree, sans-serif;
}

.la_navigation-browser {
  flex: 1;
  width: 850px;
}

.la_transfer_lead_popup {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_pop-up-send-lead {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border: 1px solid #2538824d;
  border-radius: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  padding: 40px;
  display: flex;
  position: relative;
}

.la_text-block-40 {
  color: #253882;
  text-align: center;
  width: 216px;
  font-family: Figtree, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.la_select-field {
  color: var(--white);
  background-color: #253882;
  border-radius: 5px;
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
  font-size: 13px;
  font-weight: 300;
}

.la_button-2 {
  background-color: #253883;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.la_brand {
  width: 170px;
}

.number-flag, .la_number-achievements {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 35px;
  line-height: 40px;
}

.la_title-metrics {
  color: #24398a;
  width: 100%;
  margin-bottom: 3px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.la_section-6 {
  color: var(--white);
  flex-flow: column;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.la_sticky-div-nav {
  z-index: 99;
  background-color: #f6fafc;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  position: sticky;
  top: 70px;
}

.la_sticky-div-nav.preprod {
  top: 100px;
}

.la_congrats-pop-up {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_pop-up {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #fff;
  border: 2px solid #0582ca4d;
  border-radius: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  padding: 40px 30px;
  display: flex;
}

.la_image-19 {
  width: 120px;
  margin-bottom: 15px;
}

.la_title {
  color: var(--black);
  font-family: Figtree, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.la_text-notification {
  color: var(--black);
  text-align: center;
  font-family: Figtree, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.la_paragraph {
  color: var(--dark-grey);
  text-align: center;
  font-family: Figtree, sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
}

.la_paragraph.blank {
  text-align: center;
  margin-bottom: 0;
}

.la_bold-text {
  font-size: 20px;
  line-height: 30px;
}

.la_text-block-44 {
  color: var(--white);
  text-transform: uppercase;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.la_nouvelle-fonctionalit {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_tuto-fonctionalit {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #fff;
  border: 2px solid #25388233;
  border-radius: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  padding: 30px;
  display: flex;
}

.la_tuto-fonctionalit.rappels {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 380px;
}

.la_tb45 {
  font-family: Figtree, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.la_tb45.blue {
  color: #253884;
}

.la_text-block-46 {
  text-align: center;
  font-size: 14px;
}

.la_text-block-46.blue {
  color: #253884;
}

.la_form-4 {
  background-color: #253882;
}

.success-message-2, .error-message-3 {
  background-color: #0000;
}

.la_la_filter-dropdown {
  color: var(--white);
  cursor: pointer;
  background-color: #253882;
  border: 1px solid #fff3;
  border-radius: 10px;
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
}

.la_la_filter-dropdown._2 {
  background-color: #ffffff0d;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  font-size: 12px;
}

.la_status-drop-down {
  background-color: #253882;
  margin-bottom: 0;
}

.la_filters-dropdown {
  margin-bottom: 0;
}

.la_status-dropdown {
  color: var(--white);
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fff3;
  border-radius: 10px;
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
}

.la_relative-holder-user-profile {
  justify-content: flex-end;
  align-items: center;
  min-width: 182px;
  display: flex;
  position: relative;
}

.la_holder-buttons-user {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0003;
  border-radius: 10px;
  flex-flow: column;
  padding: 10px;
  display: flex;
  position: absolute;
  inset: 110% 0% auto auto;
}

.la_button-3 {
  color: #253882;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.la_button-4 {
  color: var(--white);
  background-color: #253882;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: Figtree, sans-serif;
  font-weight: 500;
  display: flex;
}

.la-popup-warning-blur {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la-popup-warning-blur.unblur {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.la_message-pop-up {
  background-color: #fff;
  border: 2px solid #2538824d;
  border-radius: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  padding: 30px;
  display: flex;
}

.la_image-21 {
  margin-bottom: 20px;
}

.la_text-block-47 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.la_holder-buttons {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.la_button-5 {
  text-transform: uppercase;
  background-color: #d70000;
  border-radius: 5px;
  padding: 8px 20px;
}

.la_button-5.non {
  background-color: #234cea;
}

.la_pop-up-mise---jour {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la_pop-up-update {
  background-color: #fff;
  border: 2px solid #25388233;
  border-radius: 15px;
  width: 400px;
  padding: 40px 30px;
  position: relative;
}

.la_text-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
}

.la_holder-input-updates {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.la_form-5 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.la_form-block-4 {
  margin-bottom: 0;
}

.text-field-7 {
  background-color: #25388226;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 0;
}

.la_input-update {
  color: #253882e6;
  background-color: #25388226;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 0;
  padding-top: 20px;
  font-family: Figtree, sans-serif;
  font-size: 16px;
}

.la-text-input {
  color: #253882;
  text-transform: capitalize;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  inset: 7px auto auto 15px;
}

.holder-button-variants {
  position: relative;
}

.la_button-valider {
  text-transform: uppercase;
  background-color: #008c65;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Figtree, sans-serif;
  font-weight: 500;
  display: flex;
}

.la_button-valider.absolut {
  background-color: #afc7c0;
  position: absolute;
}

.la_icon-filter_clickable {
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.la_names {
  color: var(--white);
  background-color: #0000;
  border: 1px solid #0000;
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
  font-size: 26px;
  line-height: 33px;
}

.la_form-block-5 {
  width: 220px;
  margin-bottom: 0;
}

.dropdown {
  width: 100%;
}

.text-block-50 {
  text-transform: capitalize;
  font-size: 14px;
}

.la_holder-b2b-b2c_arrows {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.la_holder-b2b-b2c_arrows.only_arrows {
  flex-flow: row-reverse;
}

.la_holder-arrows {
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  display: flex;
}

.la_arrows {
  cursor: pointer;
  background-color: #253883;
  border: 1.5px solid #ffffff80;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.la_image_arrow.left {
  transform: rotate(180deg);
}

.la_holder_names {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.la_form-6 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.la-popup-warning-shade {
  z-index: 999;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.la-popup-warning-shade.unblur {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.la_column_section {
  flex-flow: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 858px;
  display: flex;
}

.la_centerer {
  width: 858px;
}

.la_center {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.la_indicateur-version {
  z-index: 999;
  background-color: #00d672;
  justify-content: center;
  align-items: center;
  height: 30px;
  display: flex;
  position: fixed;
  inset: 0% 0% auto;
}

.la_text-block-51 {
  text-transform: uppercase;
  font-weight: 700;
}

.tmp_body {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  flex-flow: column;
  display: flex;
}

.la_options-lead {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.la_calendar-button {
  cursor: pointer;
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 38px;
  display: flex;
  position: relative;
}

.la_color-stats {
  background-color: #fff;
  border: 4px solid #d36200;
  border-radius: 99px;
  width: 12px;
  height: 12px;
  position: absolute;
  inset: -5px -5px auto auto;
}

.la_tf_8 {
  color: #253882;
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  min-width: 300px;
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
  display: block;
}

.form-block-5 {
  margin-bottom: 0;
}

.la_information-de-la-vente {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: #253882;
  border: 1px solid #fff3;
  border-radius: 10px;
  flex-flow: column;
  min-width: 190px;
  padding: 10px;
  display: none;
  position: absolute;
  inset: 47px 0% auto auto;
}

.la_information-de-la-vente.show {
  display: flex;
}

.la-holder-text-variable {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 100%;
  display: flex;
}

.la_text_block-52 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 10px;
  line-height: 14px;
}

.image-20 {
  width: 10px;
  position: absolute;
  inset: -10px 12.5px auto auto;
}

.la_holder-pagination {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.la_button-pagination {
  cursor: pointer;
  background-color: #2538821a;
  border: 1px solid #253882;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: flex;
}

.la_button-pagination.clicked {
  background-color: #253882;
}

.la_numbers-pagination {
  color: #253882;
  font-weight: 500;
}

.la_numbers-pagination.clicked {
  color: #fff;
}

.la_image_21 {
  transform-style: preserve-3d;
  transform: rotateX(0)rotateY(180deg)rotateZ(0);
}

.la_form-block-6 {
  margin-bottom: 0;
}

.la_vertical_container_centered {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.la_chatbot-section {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0%;
}

.la_chatbot-section._2 {
  padding-top: 130px;
  display: flex;
}

.la_holder-input-title {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.la_holder-input-title.conversation {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  min-width: 600px;
  max-width: 750px;
  height: 100%;
  margin-bottom: 120px;
  padding: 20px 50px;
  position: relative;
  overflow: scroll;
}

.heading-10 {
  color: var(--white);
  margin-bottom: 0;
  font-family: Figtree, sans-serif;
  font-size: 30px;
  line-height: 40px;
}

.heading-10.conversation {
  display: none;
}

.la_input-chatbot {
  color: var(--white);
  background-color: #fff3;
  border: 1px solid #ffffff26;
  border-radius: 15px;
  height: 50px;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 16px;
  line-height: 24px;
}

.form-block-6 {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  margin-bottom: 0;
}

.form-block-6.conversation {
  width: 50%;
  min-width: 600px;
  max-width: 750px;
  padding-left: 30px;
  padding-right: 30px;
}

.la_relative-holder-form {
  position: relative;
}

.la-button-search {
  cursor: pointer;
  background-color: #fff3;
  border: .5px solid #fff3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  inset: 10px 10px 0% auto;
}

.la_button-close {
  position: absolute;
  inset: 100px 40px auto auto;
}

.image-21 {
  width: 15px;
}

.image-22, .image-23, .image-24 {
  width: 17px;
}

.la-holder-history-brand {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.la_button-story {
  background-color: #ffffff1a;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.menu-button {
  padding: 0 0 3px;
  display: block;
}

.menu-button.w--open {
  background-color: #ffffff1a;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 7.5px;
  padding-right: 8px;
  display: flex;
}

.navbar-2 {
  background-color: #0000;
}

.nav-menu-2 {
  background-color: #fff;
  flex-flow: column;
  max-width: 220px;
  margin-top: 70px;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  bottom: auto;
  left: 0%;
  right: auto;
}

.navbar-3 {
  background-color: #ffffff1a;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
  position: static;
}

.container-3 {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.image-25 {
  width: 14px;
}

.la_ajouter-conversation {
  background-color: #24398a1a;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.text-block-51 {
  color: #24398a;
  text-transform: capitalize;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.la_close-menu {
  position: absolute;
  inset: 5px 15px auto auto;
}

.la_ai-messages {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.text-block-52 {
  color: var(--white);
  margin-top: 3px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.image-26 {
  position: absolute;
  top: 0%;
  bottom: auto;
  left: -50px;
}

.la_ai-propositions {
  background-color: #fff3;
  border-radius: 99px;
  padding: 5px 15px;
  text-decoration: none;
}

.text-block-53 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.la_user-message {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.la_user-msg {
  background-color: #fff3;
  border-radius: 99px;
  padding: 10px 20px;
}

.la_holder-input-down {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.la_holder-input-down.conversation {
  padding-bottom: 10px;
  position: fixed;
  inset: auto 0% 0%;
}

.la_calendar-section {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: none;
}

.la_calendar-section._2nd-pop-up {
  display: none;
}

.la_calendar-section._3rd-pop-up {
  display: flex;
}

.la_holder-calendar {
  background-color: #253882;
  border-radius: 20px;
  flex-flow: column;
  width: 80%;
  min-width: 850px;
  max-width: 1053px;
  padding: 30px 15px 15px;
  display: flex;
  position: relative;
}

.la_holder-top-side {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
}

.la_holder-days {
  background-color: #fff;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  width: 142px;
  height: 26px;
  margin-right: 38px;
  display: flex;
}

.la_left-arrow {
  background-color: #2538820d;
  border-right: 1px solid #2538820d;
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  display: flex;
}

.la_right-arrow {
  background-color: #2538820d;
  border-left: 1px solid #2538820d;
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  display: flex;
}

.image-27 {
  transform-style: preserve-3d;
  transform: rotateX(0)rotateY(180deg)rotateZ(0);
}

.text-block-54 {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
}

.text-block-55 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 25px;
  line-height: 30px;
}

.la_holder-timeline {
  background-color: #fff;
  border-radius: 99px;
  justify-content: space-between;
  width: 180px;
  height: 26px;
  display: flex;
}

.la_jours {
  justify-content: center;
  align-items: center;
  width: 60px;
  text-decoration: none;
  display: flex;
}

.la_jours.middle {
  border-left: 1px solid #25388333;
  border-right: 1px solid #25388333;
}

.text-block-56 {
  color: #253883;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
}

.la_div-calendar {
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: 70vh;
  min-height: 500px;
  max-height: 590px;
  padding-top: 62px;
  position: relative;
  overflow: scroll;
}

.la_fixed-up-side {
  background-color: #e1e7f0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  flex-flow: column;
  display: flex;
  position: absolute;
  inset: 0% 0% auto;
}

.la_holder-days-calendar {
  border-bottom: 1px solid #c2c8dc;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.la_first-box {
  justify-content: center;
  align-items: center;
  width: 7.4%;
  height: 100%;
  display: flex;
}

.la_first-box.placement {
  background-color: #e1e7f0;
  width: 7.35%;
}

.la_box-days {
  background-color: #e1e7f0;
  border-right: 1px solid #c2c8dc;
  width: 13.23%;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.la_box-days.last {
  border-right-width: 0;
}

.la_box-days.first {
  border-left: 1px solid #c2c8dc;
}

.text-block-57 {
  color: #253883;
  text-align: center;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.la_holder-rappel-boxs {
  justify-content: space-between;
  align-items: center;
  height: 30px;
  display: flex;
}

.text-block-58 {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.la_holder-hours {
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 40px;
  display: flex;
}

.la_hour {
  border-bottom: 1px solid #c5cde0;
  border-right: 1px solid #c5cde0;
  width: 13.23%;
  height: 100%;
}

.la_hour.first {
  border-left: 1px solid #c5cde0;
}

.la_hoder-time {
  border-bottom: 1px solid #c5cde0;
  width: 30px;
  height: 100%;
  position: relative;
}

.la_absolut-hour {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 16px;
}

.la_absolut-hour.placement {
  position: absolute;
  inset: auto auto -8px -25px;
}

.la_close-calendar {
  padding: 10px;
  position: absolute;
  inset: 10px 20px auto auto;
}

.div-block-5 {
  width: 100%;
  height: 100%;
}

.la_event {
  background-color: #253882;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 5px 10px;
  display: flex;
}

.la_holder-name-hour {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
  display: flex;
}

.text-block-59 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
}

.text-block-60 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 6px;
  line-height: 9px;
}

.text-block-61 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-size: 6px;
  line-height: 10px;
}

.la_holder-button-ai {
  z-index: 999;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #25398a;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 15px;
  text-decoration: none;
  display: flex;
  position: fixed;
  inset: 25% 0% auto auto;
}

.text-block-62 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  display: flex;
}

.la_holder-title {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 25px;
  display: flex;
}

.la_white-dot {
  background-color: #fff;
  border-radius: 99px;
  width: 7px;
  height: 7px;
}

.text-block-63 {
  color: var(--white);
  text-transform: uppercase;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
}

.la_holder-second-calendar {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: space-between;
  display: flex;
}

.la_small-calendar {
  background-color: #f6fafc;
  border-radius: 15px;
  width: 50%;
  height: 610px;
  padding-top: 60px;
  position: relative;
  overflow: scroll;
}

.la_top-side-calendar {
  background-color: #e1e7f0;
  border-bottom: 1px solid #c2c8dc;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  flex-flow: column;
  display: flex;
  position: absolute;
  inset: 0% 0% auto;
}

.la_holder-days-small-calendar {
  border-bottom: 1px solid #c2c8dc;
  width: 100%;
  height: 35px;
  display: flex;
}

.la_days {
  border-right: 1px solid #c2c8dc;
  justify-content: center;
  align-items: center;
  width: 17%;
  height: 100%;
  display: flex;
}

.text-block-64 {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.la_couter-days {
  justify-content: center;
  align-items: center;
  width: 83%;
  height: 100%;
  display: flex;
}

.text-block-65 {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
}

.la_holder-rappels {
  height: 25px;
  display: flex;
}

.la_holder-rappel {
  width: 83%;
  height: 100%;
}

.la_holder-hours-small-calendar {
  flex-flow: column;
  display: flex;
}

.la_hours-small-calendar {
  justify-content: flex-end;
  height: 40px;
  display: flex;
}

.la_event-holder {
  border-bottom: 1px solid #c2c8dc;
  border-left: 1px solid #c2c8dc;
  width: 83%;
  height: 100%;
}

.la_holder-time-relative {
  justify-content: flex-end;
  align-items: stretch;
  width: 17%;
  height: 100%;
  display: flex;
}

.la_relative-holder-time {
  border-bottom: 1px solid #c2c8dc;
  width: 30px;
  height: 100%;
  position: relative;
}

.la_time-absolut {
  color: #253883;
  font-family: Figtree, sans-serif;
  font-size: 9px;
  line-height: 16px;
  position: absolute;
  inset: auto auto -8px -30px;
}

.la_holder-right-part-small-calendar {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  width: 50%;
  display: flex;
}

.la_holder-events {
  background-color: #f6fafc;
  border-radius: 15px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 50%;
  padding: 20px;
  display: flex;
}

.la_holder-title-p {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.la_blue-dot {
  background-color: #253883;
  border-radius: 99px;
  width: 7px;
  height: 7px;
}

.text-block-66 {
  color: #253883;
  text-transform: uppercase;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.text-field-8 {
  color: #253882;
  background-color: #2538821a;
  border: 1px solid #25388233;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 0;
  padding-left: 15px;
}

.la_holder-input-event {
  width: 50%;
  position: relative;
}

.la_holder-form-inputs {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  display: flex;
}

.la_first-inputs {
  width: 100%;
  margin-bottom: 0;
}

.image-28 {
  position: absolute;
  inset: 10px 15px 0% auto;
}

.la_holder-input-search-event {
  position: relative;
}

.form-block-7 {
  width: 100%;
  margin-bottom: 0;
}

.text-field-9 {
  margin-bottom: 0;
}

.form-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.image-29 {
  position: absolute;
  inset: 10px 10px 0% auto;
}

.la_button-create-event {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  background-color: #253882;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.text-block-67 {
  color: var(--white);
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.la_holder-inputs-forms {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  width: 100%;
  display: flex;
}

.la_holder-information-leads {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #f6fafc;
  border-radius: 15px;
  flex-flow: column;
  height: 50%;
  padding: 10px 20px 20px;
  display: flex;
}

.la_holder-title-input-search {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.text-field-10, .form-block-8 {
  margin-bottom: 0;
}

.image-30 {
  position: absolute;
  inset: 10px 10px 0% auto;
}

.la_holder-buttons-event {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.la_button-supprimer {
  cursor: pointer;
  background-color: #ed4337;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.la_holder-leads-search {
  border: .5px solid #dce0fc;
  border-radius: 5px;
  flex-flow: column;
  height: 220px;
  padding-top: 40px;
  display: flex;
  position: relative;
  overflow: scroll;
}

.la_leads-informations {
  background-color: #25388305;
  border: .5px solid #dce0fc;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  inset: 0% 0% auto;
}

.text-block-68 {
  color: #333;
  text-transform: uppercase;
  width: 94px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.la_informations-lead {
  background-color: #25388305;
  border-bottom: 1px solid #dce0fc;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.la_informations-lead:hover {
  background-color: #25388314;
}

.text-block-69 {
  color: #253883;
  width: 94px;
  font-family: Figtree, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
}

.la_holder-lead-info {
  flex-flow: column;
  display: flex;
}

.la_menu-ai-bot {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  flex-flow: column;
  width: 220px;
  margin-top: 70px;
  padding: 10px;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.la_holder-historique {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  display: flex;
}

.text-block-70 {
  color: #25398a;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.la_holder-timeline-historique {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
}

.text-block-71 {
  color: #25398a;
  padding-left: 10px;
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.image-31 {
  width: 20px;
}

.la_holder-historique-event {
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.la_holder-historique-event:hover {
  background-color: #25398a1a;
}

.text-block-72 {
  color: #333;
  font-size: 12px;
}

.text-field-11 {
  color: var(--white);
  background-color: #ffffff1a;
  border: 1px solid #fff3;
  border-radius: 5px;
  max-width: 210px;
  min-height: 50px;
  font-family: Figtree, sans-serif;
  font-size: 15px;
}

.dropdown-toggle {
  background-color: #ffffff1a;
  border: 1px solid #fff3;
  border-radius: 5px;
  min-width: 210px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
}

.dropdown-toggle.first {
  margin-bottom: 10px;
}

.icon-4 {
  color: #fff;
  margin-right: 10px;
}

.text-block-73 {
  color: #ffffff80;
  font-family: Figtree, sans-serif;
  font-size: 15px;
}

.form-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.button-2 {
  background-color: #25398a;
  border-radius: 10px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Figtree, sans-serif;
}

.form-block-9 {
  margin-bottom: 0;
}

.dropdown-list {
  background-color: #ddd;
}

.dropdown-list.w--open {
  z-index: 9999;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #0009;
  border: 1px solid #fff3;
  border-radius: 5px;
}

.dropdown-link {
  color: #fff;
  font-family: Figtree, sans-serif;
  transition: background-color .25s;
}

.dropdown-link:hover {
  background-color: #ffffff1a;
}

.la_holder-name-time-reminder {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.la_fixed-chatbot {
  z-index: 999;
  background-color: #25398a;
  border-radius: 10px;
  flex-flow: column;
  width: 320px;
  height: 490px;
  display: flex;
  position: fixed;
  inset: auto 20px 20px auto;
  box-shadow: 0 0 5px #0003;
}

.la_up-side-fixed-chatbot {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  display: flex;
  position: relative;
}

.la_holder-headers {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.text-block-74 {
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
}

.text-block-75 {
  font-family: Figtree, sans-serif;
  font-size: 9px;
  font-weight: 300;
  line-height: 12px;
}

.la_holder-buttons-fixed-chatbot {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 10px 20px auto auto;
}

.la_holder-conversation {
  background-image: linear-gradient(#fff, #fff 90%, #f5f4f4);
  height: 100%;
  max-height: 390px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: inset 0 -5px 14px #0000000d;
}

.la_aligne-conversation {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  overflow: scroll;
}

.la_aibot-message {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.la_box-message-bot {
  background-color: #efefef;
  border-radius: 10px 10px 10px 0;
  max-width: 250px;
  padding: 10px;
}

.la_box-message-bot.astuce {
  flex-flow: column;
  padding: 0;
  display: flex;
}

.text-block-76 {
  color: #333;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  line-height: 14px;
}

.la_user-message-box {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.la_user-blue-box {
  background-color: #253882;
  border-radius: 10px 10px 0;
  padding: 10px;
}

.text-block-77 {
  font-family: Figtree, sans-serif;
  font-size: 10px;
  line-height: 14px;
}

.la_holder-astuce-text {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.text-block-78 {
  color: #333;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
}

.text-block-79 {
  color: #333;
  font-family: Figtree, sans-serif;
  font-size: 9px;
  line-height: 12px;
}

.la_holder-button-astuce {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  display: flex;
}

.la_button-astuce {
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: Figtree, sans-serif;
  font-weight: 500;
}

.la_holder-input-conversation {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  display: flex;
  position: relative;
}

.text-field-12 {
  border: 1px solid #0000;
  width: 100%;
  min-width: 250px;
  margin-bottom: 0;
  padding-left: 20px;
}

.form-block-10 {
  margin-bottom: 0;
}

.la_holder-files-emojis {
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-right: 15px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.la_link-files, .la_link-emojis {
  padding: 5px;
}

.image-32 {
  height: 30px;
}

.la_holder-nova-crm {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 70px;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 40px;
  display: flex;
}

.la_full-crm {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  display: flex;
}

.la_holder-lead-stats {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  margin-bottom: 10px;
  display: flex;
}

.la_stats {
  background-color: #fff;
  border: 1px solid #74747433;
  border-radius: 5px;
  height: 50px;
  padding: 10px 12px;
}

.la_stats.specs {
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 7px 10px;
  display: flex;
}

.text-block-80 {
  color: var(--blue-nova);
  text-transform: capitalize;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.text-block-81 {
  color: var(--blue-nova);
  text-transform: capitalize;
  font-family: Figtree, sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
}

.text-block-82 {
  color: var(--blue-nova);
  font-family: Figtree, sans-serif;
  font-size: 16px;
  line-height: 15px;
}

.la_holder-crm-leads {
  flex-flow: row;
  justify-content: space-between;
  display: flex;
}

.la_holder-changes {
  background-color: #fff;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 34px;
  display: flex;
  box-shadow: 0 0 0 1px #0000000d;
}

.la_body {
  background-color: #f9f9f9;
}

.la_holder-button-changes {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  cursor: pointer;
  border-right: 1px solid #ededed;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.la_holder-button-changes:hover {
  background-color: #e9ecfa;
}

.text-block-83 {
  color: var(--dark-gray);
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.text-block-83.blue {
  color: var(--blue-change);
}

.text-block-83.hide {
  display: none;
}

.la_search-crm {
  border-radius: 5px;
  min-width: 200px;
  height: 34px;
  margin-bottom: 0;
}

.form-block-11 {
  margin-bottom: 0;
}

.form-4 {
  position: relative;
}

.la_button-search {
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.la_holder-crm-info {
  border-top-left-radius: 5px;
  position: relative;
}

.la_horizontal-slide-info {
  background-color: #ffffff80;
  border: 1px solid #cacaca;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow: scroll;
}

.la_info-line {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  min-width: 200px;
  padding-left: 10px;
  display: flex;
}

.la_info-line._2 {
  width: 150px;
  min-width: 150px;
}

.la_info-line._2.dropdown {
  padding-right: 10px;
}

.la_info-line.input {
  padding-left: 0;
}

.la_image-info-line {
  width: 14px;
}

.la_text-type-info-line {
  color: #a4a4a4;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.la_holder-horizontal-info {
  height: 30px;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.la_holder-horizontal-info:hover {
  background-color: #e9ecfa;
}

.la_holder-horizontal-info.first {
  border-top-left-radius: 5px;
  min-height: 30px;
}

.la_input-information {
  color: var(--dark-gray);
  background-color: #0000;
  border: 1px solid #0000;
  width: 100%;
  height: 30px;
  margin-bottom: 0;
  padding-left: 10px;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

.form-block-12 {
  width: 100%;
  margin-bottom: 0;
}

.la_dropdown-status {
  background-color: #81c594;
  border-radius: 99px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.la_dropdown-status.orange {
  background-color: #e4a57e;
}

.la_dropdown-status.grey {
  background-color: #a8a8a8;
}

.la_dropdown-status.red {
  background-color: #df7c7c;
}

.dropdown-toggle-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  display: flex;
}

.la_text-input-status {
  color: var(--white);
  text-transform: uppercase;
  font-family: Figtree, sans-serif;
  font-size: 10px;
  line-height: 12px;
}

.icon-5 {
  color: var(--white);
  right: -12px;
}

.la_small-white-cercle {
  background-color: #fff;
  border-radius: 30px;
  width: 6px;
  height: 6px;
}

.la_dropdown-link-status-holder {
  border-radius: 5px;
  overflow: hidden;
}

.la_dropdown-link-status-holder.w--open {
  z-index: 30;
  background-color: #0000;
  inset: 0% 0% auto;
}

.la_drop-down-link {
  color: var(--white);
  text-transform: uppercase;
  background-color: #81c594;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.la_drop-down-link.orange {
  background-color: #e4a57e;
}

.la_drop-down-link.grey {
  background-color: #a8a8a8;
}

.la_drop-down-link.red {
  background-color: #df7c7c;
}

.la_buttons-edit-lead {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  display: flex;
}

.la_button-copier {
  cursor: pointer;
  border: .3px solid #a4a4a4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.la_button-fonctionnalite {
  cursor: pointer;
  border: .3px solid #a4a4a4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
}

.form-block-13 {
  margin-bottom: 0;
}

.checkbox-field {
  border-radius: 5px;
  margin-bottom: 0;
  padding-left: 0;
}

.checkbox-label {
  margin-bottom: 0;
  display: none;
}

.checkbox {
  border: .3px solid #a4a4a4;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-left: 0;
}

.la_button-rappeler {
  background-color: #e8e8e8;
  border: .3px solid #a4a4a4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.la_holder-button-changes-lead {
  padding-top: 30px;
  position: absolute;
  inset: 0% auto 0% -75px;
}

@media screen and (min-width: 1280px) {
  .section.bg-light-blue.none {
    padding-right: 60px;
  }

  .container {
    flex-flow: column;
    align-items: stretch;
  }

  .button.button-green {
    font-size: 20px;
    line-height: 30px;
  }

  .grid-cta-simple {
    background-image: linear-gradient(90deg, #0000, #fbfcffc2);
  }

  .conseils-text-wrapper {
    width: auto;
    max-width: 470px;
  }

  .paragraph-3 {
    font-size: 22px;
    line-height: 32px;
  }

  .holder-blogs {
    max-width: none;
  }

  .link-block {
    width: 360px;
    height: 490px;
    padding: 15px;
  }

  .h1 {
    font-size: 22.5px;
    line-height: 28px;
  }

  .text-block-7 {
    font-size: 13px;
    line-height: 19px;
  }

  .text-block-8 {
    font-size: 20px;
    line-height: 30px;
  }

  .image-5 {
    width: 20px;
  }

  .slide-nav {
    font-size: 5px;
    line-height: 5px;
  }

  .holder-image-cover {
    height: 190px;
  }

  .container-div {
    justify-content: space-between;
    align-items: center;
  }

  .container-div.flex-verticale {
    max-width: 1400px;
  }

  .right-arrow {
    width: 40%;
  }

  .slide-1 {
    width: 400px;
  }

  .slider-4 {
    max-width: 700px;
    height: 570px;
  }

  .mask {
    width: 410px;
  }

  .heading-5 {
    font-size: 50px;
    line-height: 60px;
  }

  .section-3 {
    height: 600px;
    display: none;
  }

  .text-content {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .image-content {
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .image-7 {
    object-fit: cover;
  }

  .holder-content {
    flex-flow: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    display: flex;
  }

  .holder-link-pages {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
  }

  .linked-pages {
    color: var(--dark-gray);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }

  .linked-pages:hover {
    text-decoration: underline;
  }

  .text-block-9 {
    color: var(--dark-gray);
    margin-right: 5px;
    font-size: 13px;
    line-height: 20px;
  }

  .text-block-10, .text-block-11 {
    margin-right: 5px;
    font-weight: 400;
  }

  .heading-6 {
    font-weight: 500;
  }

  .text-block-12 {
    margin-bottom: 40px;
    font-size: 19px;
    line-height: 28px;
  }

  .holder-autor {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .image-8 {
    object-fit: cover;
    border-radius: 99px;
    width: 50px;
    height: 50px;
  }

  .holder-image-autor {
    object-fit: cover;
    margin-right: 15px;
    overflow: hidden;
  }

  .rich-text-block {
    width: 100%;
    max-width: 700px;
  }

  .section-4 {
    padding-left: 0;
  }

  .sticky-div-holder {
    width: 30%;
    min-width: 350px;
    max-width: 450px;
    position: sticky;
    top: 90px;
  }

  .somaire-article {
    background-color: #f0f0f0;
    border-radius: 10px;
    flex-flow: column;
    width: 100%;
    margin-bottom: 20px;
    padding: 40px 30px;
    display: flex;
    box-shadow: 2px 2px 1px #0003;
  }

  .linked-in-page {
    margin-bottom: 5px;
    padding-bottom: 10px;
    line-height: 20px;
    text-decoration: none;
  }

  .linked-in-page:hover {
    text-decoration: underline;
  }

  .bold-text {
    font-weight: 700;
    line-height: 25px;
  }

  .heading-7 {
    margin-bottom: 20px;
  }

  .div-ctc {
    background-color: var(--primary);
    background-image: linear-gradient(135deg, #0000, #fbfcffbf);
    border-radius: 10px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
    box-shadow: 2px 2px 1px #0003;
  }

  .text-block-13 {
    text-align: left;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 500;
    line-height: 39px;
  }

  .holder-title {
    max-width: 1000px;
  }

  .bold-text-2, .bold-text-3, .heading-9, .bold-text-4 {
    font-weight: 500;
  }

  .la_text-tuto {
    font-weight: 400;
  }

  .la_navigation-browser {
    border-radius: 20px;
  }

  .la_input-chatbot {
    width: 100%;
    min-width: 550px;
  }

  .form-block-6 {
    margin-bottom: 0;
  }

  .la_holder-nova-crm {
    padding-left: 100px;
    padding-right: 50px;
  }

  .la_horizontal-slide-info {
    overflow: scroll;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 35px;
    line-height: 45px;
  }

  h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .section {
    padding: 75px 20px;
  }

  .section.section-hero-full {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .button {
    padding-left: 32px;
    padding-right: 32px;
  }

  .nav-link {
    justify-content: flex-end;
    width: 100%;
    margin-left: 0;
    margin-right: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
  }

  .nav-link.button-nav-link, .nav-link.button-nav-link-yellow {
    width: auto;
  }

  .navbar {
    z-index: 800;
    padding-left: 50px;
    padding-right: 50px;
  }

  .menu-burger {
    margin-left: 16px;
  }

  .menu-burger.w--open {
    color: var(--black);
    background-color: #0000;
  }

  .nav-menu {
    z-index: 800;
    background-color: var(--white);
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
    display: flex;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    padding: 20px 80px;
    display: flex;
  }

  .step-grid-home {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .brand-link-navigation {
    margin-right: 24px;
  }

  .grid-cta {
    grid-template-columns: 1fr 1fr;
  }

  .subscribe-box {
    padding-left: 0;
  }

  .subscribe-form {
    align-items: flex-start;
  }

  .footer {
    padding-left: 50px;
    padding-right: 50px;
  }

  .footer-social {
    flex: none;
  }

  .footer-bottom-left-side {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
  }

  .footer-socials {
    margin-bottom: 12px;
  }

  .footer-bottom {
    padding-top: 24px;
  }

  .hamburger-line {
    background-color: var(--black);
  }

  .hamburger-line.light {
    background-color: #fff;
  }

  .link-navigation {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .grid-hero-home {
    padding-left: 0;
    padding-right: 0;
  }

  .box-step-wrapper {
    text-align: center;
    align-items: center;
  }

  .dropdown-navigation {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .dropdow-toggle-nav {
    margin-bottom: 16px;
    padding-right: 0;
    display: flex;
  }

  .dropdow-toggle-nav.w--open {
    margin-bottom: 0;
  }

  .dropdown-list-nav.w--open {
    background-color: #0000;
    position: static;
  }

  .dropdown-link-nav {
    color: var(--black);
    text-align: center;
    background-color: #0000;
  }

  .dropdown-link-nav:hover {
    background-color: #0000;
  }

  .dropdown-link-nav.w--current {
    color: var(--black);
  }

  .icon {
    order: 1;
    margin-left: 8px;
    margin-right: 0;
    display: block;
    position: static;
  }

  .icon-grid-home {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .image-home {
    object-fit: contain;
  }

  .content-box-hero-about {
    max-width: 75%;
  }

  .link-mobile-contact {
    margin-bottom: 16px;
    margin-right: 0;
    display: flex;
  }

  .form-question {
    margin-top: 24px;
  }

  .c-navbar {
    z-index: 800;
    padding-left: 50px;
    padding-right: 50px;
  }

  .c-navigation-wrapper {
    justify-content: center;
  }

  .c-grid-hero {
    grid-template-columns: 1.25fr 1fr;
    padding-left: 0;
    padding-right: 0;
  }

  .c-grid-presentation {
    grid-column-gap: 32px;
  }

  .c-p-navigation {
    display: none;
  }

  .c-logo-wrapper {
    margin-top: 16px;
    margin-left: 0;
  }

  .c-p-hero-section {
    display: none;
  }

  .c-button-hero-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .grid-full-page-home {
    grid-template-columns: 1fr;
    justify-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  .text-wrapper-full-home {
    text-align: center;
    align-items: center;
  }

  .navbar-dark {
    z-index: 800;
    padding-left: 50px;
    padding-right: 50px;
  }

  .link-navigation-white {
    color: var(--black);
    margin-bottom: 16px;
    margin-right: 0;
  }

  .link-navigation-white:hover {
    color: var(--dark-gray);
  }

  .dropdow-toggle-nav-white {
    color: var(--black);
    margin-bottom: 16px;
    padding-right: 0;
    display: flex;
  }

  .dropdow-toggle-nav-white.w--open {
    margin-bottom: 0;
  }

  .dropdown-list-nav-white.w--open {
    background-color: #0000;
    position: static;
  }

  .dropdown-link-nav-dark {
    color: var(--black);
    text-align: center;
    background-color: #0000;
  }

  .dropdown-link-nav-dark:hover {
    background-color: #0000;
  }

  .dropdown-link-nav-dark.w--current {
    color: var(--black);
  }

  .title-hero-home {
    font-size: 50px;
  }

  .illustration-home-hero {
    width: 70%;
  }

  .arguments-wrapper-hero {
    margin-left: auto;
    margin-right: auto;
  }

  .argument-card {
    text-align: center;
    align-items: center;
  }

  .half-section {
    width: 60%;
  }

  .half-section.image {
    width: 40%;
  }

  .image-3 {
    width: 100%;
  }

  .text-block-3 {
    font-size: 42px;
    line-height: 48px;
  }

  .text-block-4 {
    font-family: Poppins, sans-serif;
  }

  .image-content {
    object-fit: cover;
    width: 40%;
  }

  .image-7 {
    height: 100%;
  }

  .text-block-10, .text-block-11 {
    font-size: 14px;
  }

  .heading-6 {
    font-size: 30px;
    line-height: 38px;
  }

  .text-block-12 {
    font-size: 14px;
    line-height: 22px;
  }

  .image-8, .holder-image-autor {
    width: 40px;
    height: 40px;
  }

  .section-4 {
    padding: 60px;
  }

  .sticky-div-holder {
    min-width: 250px;
    max-width: 270px;
  }

  .somaire-article.hide {
    display: none;
  }

  .bold-text {
    font-size: 12px;
    line-height: 18px;
  }

  .heading-7 {
    font-size: 20px;
  }

  .div-ctc {
    padding-left: 20px;
    padding-right: 20px;
  }

  .div-ctc.hide {
    display: none;
  }

  .text-block-13 {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
  }

  .holder-blogs-post {
    grid-row-gap: 20px;
    flex-flow: column;
  }

  .heading-8 {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
  }

  .text-block-14 {
    font-size: 15px;
  }

  .text-block-15 {
    font-size: 14px;
    line-height: 20px;
  }

  .holder-links-blogs {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 10px;
  }

  .la_column_section {
    min-width: 90vw;
  }

  .nav-menu-2 {
    margin-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .section {
    padding: 50px 40px;
  }

  .section.about-computer {
    display: none;
  }

  .section.about-mobile {
    display: block;
  }

  .section.section-hero-full {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .button {
    padding: 16px 26px;
  }

  .button.button-small, .button.button-footer, .button.button-call-action {
    padding: 8px 26px;
  }

  .nav-link.button-nav-link, .nav-link.button-nav-link-yellow {
    display: none;
  }

  .navbar {
    background-color: var(--white);
    padding-left: 40px;
    padding-right: 40px;
    position: sticky;
    inset: 0% 0% auto;
  }

  .nav-list {
    padding-left: 40px;
    padding-right: 40px;
  }

  .brand-link-navigation.w--current {
    justify-content: flex-start;
    align-items: center;
  }

  .nav-box {
    margin-right: -18px;
  }

  .decoration-element.element-1 {
    opacity: .5;
    bottom: 130px;
  }

  .decoration-element.element-intro {
    opacity: .5;
    bottom: -29px;
    right: 37px;
  }

  .grid-cta {
    grid-row-gap: 16px;
    text-align: center;
    flex-direction: column;
    grid-template-columns: 1fr;
    padding-left: 48px;
    padding-right: 48px;
  }

  .subscribe-form {
    grid-template-columns: 1fr;
  }

  .footer {
    padding: 40px;
  }

  .footer-bottom-left-side {
    flex-direction: column;
    align-items: flex-start;
  }

  .copyright {
    text-align: center;
    margin-top: 8px;
  }

  .footer-socials {
    margin-bottom: 16px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .grid-hero-home {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .text-wrapper-hero-home {
    text-align: center;
    align-items: center;
    padding: 24px;
  }

  .guide-collection-list {
    grid-template-columns: 1fr;
  }

  .grid-footer {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .footer-logo-wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .brand-link-footer {
    margin-bottom: 32px;
  }

  .grid-cta-simple {
    grid-row-gap: 32px;
    text-align: center;
    background-image: linear-gradient(to right, #0000, #0000);
    flex-direction: column;
    grid-template-columns: 1fr;
    place-items: flex-start center;
    padding-left: 48px;
    padding-right: 48px;
  }

  .image-bloc-home {
    height: 250px;
    display: none;
  }

  .conseils-collection-list, .grid-conseils {
    grid-template-columns: 1fr;
  }

  .conseils-text-wrapper {
    width: 100%;
  }

  .grid-wall-reviews {
    grid-template-columns: 1fr 1fr;
  }

  .review-wrapper-wall {
    min-height: auto;
  }

  .expert-wrapper {
    grid-template-columns: 1fr;
  }

  .logo-partenaires-grid {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }

  .partenaires-wrapper {
    max-width: 100%;
  }

  .call-action-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .navigation-wrapper {
    margin-top: 0;
  }

  .image-decoration-hero-wrapper {
    height: 35%;
    padding-bottom: 35%;
  }

  .content-box-hero-about {
    max-width: 100%;
  }

  .grid-contact {
    grid-template-columns: 1.5fr;
  }

  .link-mobile-contact {
    background-color: var(--black);
    color: var(--white);
    margin-bottom: 0;
    padding: 8px 16px;
    display: block;
  }

  .link-mobile-contact:hover {
    color: var(--white);
    text-decoration: none;
  }

  .call-button-wrapper {
    display: none;
  }

  .go-top-wrapper {
    display: flex;
  }

  .cookies {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .c-button {
    padding: 16px 26px;
  }

  .c-heading-h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .c-heading-h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .c-heading-h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .c-navbar {
    background-color: var(--white);
    padding-left: 40px;
    padding-right: 40px;
    position: static;
  }

  .c-grid-hero {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .c-text-wrapper-hero {
    text-align: center;
    align-items: center;
    padding: 24px;
  }

  .c-grid-more-info {
    grid-template-columns: 1fr;
  }

  .c-grid-presentation {
    grid-column-gap: 24px;
  }

  .c-more-info-text-wrapper {
    align-items: center;
  }

  .grid-form {
    grid-template-columns: 1fr;
  }

  .c-button-submit {
    padding: 16px 26px;
  }

  .heading-confirmation {
    font-size: 48px;
    line-height: 58px;
  }

  .c-logo-wrapper {
    width: 75px;
    height: 75px;
    right: -15px;
  }

  .image {
    width: 70px;
  }

  .c-button-hero-wrapper {
    align-items: center;
  }

  .grid-download-guide {
    grid-template-columns: 1.5fr;
  }

  .download-text-wrapper {
    text-align: center;
    align-items: center;
  }

  .icon-download {
    display: none;
  }

  .grid-full-page-home {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .text-wrapper-full-home {
    text-align: center;
    align-items: center;
  }

  .navbar-dark {
    padding-left: 40px;
    padding-right: 40px;
    position: sticky;
    inset: 0% 0% auto;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .image-contact-rdv {
    object-position: 50% 25%;
    border-radius: 50%;
    width: 270px;
    height: 270px;
  }

  .title-hero-home {
    font-size: 42px;
  }

  .text-cta-scroll-desktop {
    display: none;
  }

  .text-cta-scroll-mobile {
    margin-top: 4px;
    font-size: 10px;
    line-height: 17px;
    display: block;
  }

  .summary-wrapper {
    padding: 20px;
  }

  .cta-in-article {
    grid-row-gap: 32px;
    text-align: center;
    background-image: linear-gradient(to right, #0000, #0000);
    flex-direction: column;
    grid-template-columns: 1fr;
    place-items: center;
    padding-left: 48px;
    padding-right: 48px;
  }

  .title-cta-article {
    margin-right: 0;
  }

  .logo-cta-scroll {
    max-height: 45px;
  }

  .section-2 {
    flex-flow: column;
    height: auto;
  }

  .half-section {
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .half-section.image {
    width: 100%;
    padding-top: 60px;
  }

  .button-retour {
    top: 2%;
  }

  .text-block-3, .text-block-4 {
    text-align: center;
  }

  .link-block._0opcity {
    display: none;
  }

  .container-div.flex-verticale {
    flex-flow: column;
  }

  .section-3 {
    flex-flow: column;
    height: auto;
  }

  .text-content {
    width: 100%;
  }

  .image-content {
    width: 100%;
    height: 250px;
    overflow: hidden;
  }

  .image-7 {
    height: auto;
  }

  .rich-text-block {
    width: 100%;
    margin-right: 0;
  }

  .section-4 {
    flex-flow: column;
    padding: 40px;
  }

  .sticky-div-holder, .sticky-div-holder.hide {
    display: none;
  }

  .somaire-article {
    width: 100%;
  }

  .somaire-article.hide {
    display: none;
  }

  .bold-text {
    font-size: 14px;
  }

  .div-ctc {
    width: 100%;
    padding: 40px;
  }

  .div-ctc.hide {
    display: none;
  }

  .text-block-13 {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 40px;
  }

  .sommaire-article {
    background-color: var(--light-gray);
    border-radius: 10px;
    width: 100%;
    padding: 30px;
    box-shadow: 2px 2px 1px #0003;
  }

  .holder-links-blogs {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    margin-bottom: 20px;
  }

  .la_nav-lead-access {
    padding-top: 10px;
  }

  .la_holder-elements-metrics.last {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text-block-16 {
    text-align: left;
    font-size: 11px;
  }

  .la_icon-metrics {
    width: 30px;
  }

  .la_holder-emoji-number {
    width: 90px;
    height: 40px;
  }

  .la_number-calendar {
    font-size: 27px;
  }

  .la_image-10 {
    width: 30px;
    margin-right: 8px;
  }

  .la_image-10._1 {
    width: 30px;
    height: 30px;
  }

  .la_holder-text-tuto.last {
    height: 140%;
  }

  .la_holder-tuto, .la_holder-tuto.last {
    inset: -22px 0% auto auto;
  }

  .la_holder-emojis {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .la_pop-up-send-lead {
    min-width: 400px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3 {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
  }

  h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button {
    text-align: center;
    align-self: center;
  }

  .button.button-newsletter {
    width: 100%;
  }

  .nav-link.button-nav-link, .nav-link.button-nav-link-yellow {
    display: none;
  }

  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .size-2 {
    font-size: 32px;
    line-height: 42px;
  }

  .nav-list {
    padding-left: 24px;
    padding-right: 24px;
  }

  .logo-image {
    width: 112px;
    margin-left: 0;
  }

  .step-grid-home {
    grid-row-gap: 40px;
  }

  .decoration-element.element-1 {
    display: none;
  }

  .grid-cta {
    text-align: center;
    background-image: none;
    flex-direction: column;
    padding: 32px 24px;
  }

  .subscribe-form {
    flex-wrap: wrap;
    grid-template-columns: 1fr;
  }

  .field-newsletter {
    border-radius: 0;
    max-width: 100%;
    margin-bottom: 0;
  }

  .footer {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-social {
    margin-right: 16px;
  }

  .footer-bottom-left-side {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  .footer-socials {
    margin-bottom: 16px;
  }

  .la_search {
    flex-wrap: wrap;
  }

  .text-wrapper-guide {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .grid-cta-simple {
    background-image: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
    display: flex;
  }

  .grid-logos-partenaires {
    grid-row-gap: 0px;
    grid-template-columns: 1fr 1fr;
  }

  .conseils-home-collection-list {
    grid-template-columns: 1fr;
  }

  .newsletter-wrapper {
    padding: 16px;
  }

  .form-block {
    flex-direction: row;
  }

  .conseils-text-wrapper {
    text-align: center;
    width: 60%;
  }

  .icon-grid-home {
    grid-row-gap: 40px;
  }

  .grid-wall-reviews {
    grid-template-columns: 1fr;
  }

  .review-wrapper-wall {
    padding: 16px;
  }

  .expert-wrapper {
    margin-bottom: 50px;
  }

  .logo-partenaires-grid {
    grid-template-columns: 1fr 1fr;
  }

  .partenaires-wrapper {
    padding: 16px;
    box-shadow: 0 0 5px #0000;
  }

  .logo-partenaire {
    width: 112px;
    margin-left: 0;
    padding: 0;
  }

  .call-action-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navigation-wrapper {
    margin-top: 0;
  }

  .image-decoration-hero-wrapper {
    height: 45%;
    padding-bottom: 45%;
  }

  .image-wrapper-contact {
    height: 100%;
    padding-bottom: 100%;
  }

  .image-contact {
    width: 100%;
  }

  .button-calendly, .contact-text-wrapper {
    text-align: center;
  }

  .field-call {
    border-radius: 0;
    max-width: 100%;
    margin-bottom: 0;
  }

  .btn-wrapper-faq {
    justify-content: center;
  }

  .c-button {
    text-align: center;
    align-self: center;
  }

  .c-navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .c-navigation-wrapper {
    margin-top: 0;
  }

  .c-tab-link {
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
  }

  .c-tab-pane {
    padding: 16px;
  }

  .c-tabs-menu {
    flex-direction: column;
  }

  .c-more-info-wrapper, .c-info-wrapper {
    padding: 16px;
  }

  .c-grid-presentation {
    grid-template-columns: 1fr;
  }

  .c-presentation-block {
    text-align: center;
  }

  .c-button-submit {
    text-align: center;
    align-self: center;
  }

  .download-text-wrapper {
    text-align: center;
  }

  .conseils-download-collection-list {
    grid-template-columns: 1fr;
  }

  .heading {
    text-align: center;
  }

  .navbar-dark {
    padding-left: 20px;
    padding-right: 20px;
  }

  .contact-text-bloc {
    text-align: center;
  }

  .image-contact-rdv {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .arguments-wrapper-hero {
    grid-template-columns: 1fr;
  }

  .cta-in-article {
    background-image: none;
    flex-direction: column;
    padding: 32px 24px;
  }

  .link-block {
    width: 260px;
    height: 460px;
  }

  .link-block.w--current {
    width: 300px;
  }

  .image-4 {
    width: 100%;
    height: 100%;
  }

  .h1 {
    font-size: 18px;
    line-height: 23px;
  }

  .text-block-7 {
    font-size: 11px;
    line-height: 17px;
  }

  .image-6 {
    opacity: 0;
  }

  .slide-1 {
    width: 275px;
  }

  .text-content {
    padding: 0;
  }

  .holder-content {
    margin-bottom: 40px;
  }

  .text-block-10, .text-block-11 {
    font-size: 12px;
  }

  .heading-6 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 34px;
  }

  .section-4 {
    padding: 20px;
  }

  .text-block-13 {
    text-transform: capitalize;
    font-size: 27px;
  }

  .section-5 {
    padding-right: 20px;
  }

  .heading-8 {
    font-size: 28px;
  }

  .text-block-14 {
    font-size: 13px;
  }

  .text-block-15 {
    font-size: 12px;
  }

  .la_nav-lead-access {
    background-color: #243786;
  }

  .la_holder-elements-metrics {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .la_holder-elements-metrics.last {
    justify-content: center;
    align-items: center;
  }

  .text-block-16 {
    color: #fff;
    font-size: 8px;
    display: none;
  }

  .la_holder-icon-number {
    box-shadow: none;
    background-color: #0000;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
  }

  .la_icon-metrics {
    width: 20px;
    margin-right: 0;
    padding-bottom: 5px;
  }

  .la_holder-emoji-number {
    width: 60px;
  }

  .la_number-calendar {
    font-size: 21px;
  }

  .la_image-10 {
    width: 28px;
    margin-right: 3px;
  }

  .la_holder-text-tuto {
    border-radius: 0;
    width: 100vw;
    display: none;
    inset: 100% 0% auto;
  }

  .la_holder-text-tuto.last {
    height: 120%;
  }

  .la_holder-text-tuto._2 {
    height: 110%;
    display: none;
    inset: 100% 0% 0% -100%;
  }

  .la_holder-tuto {
    background-color: #fff;
    border-radius: 99px;
    top: 5px;
    left: auto;
    right: 5px;
  }

  .la_holder-tuto.last {
    inset: 5px 5px auto auto;
  }

  .la_holder-tutotxt {
    color: #243786;
    font-size: 11px;
    font-weight: 500;
  }

  .la_holder-emojis {
    width: 28px;
    height: 28px;
    margin-right: 3px;
  }
}

#w-node-c9aeb089-a4cb-3e6c-930b-c523d3e84223-1824b912 {
  justify-self: end;
}

#w-node-_5241750a-adde-a0a6-6e80-5656ecc3fa95-1824b912 {
  justify-self: start;
}

#w-node-_9f220487-fe3d-775d-8b57-b2608248d8cd-1824b979 {
  justify-self: end;
}

#w-node-ca4d167a-9b13-9442-d641-6360c4c0928b-1824b9d8 {
  align-self: stretch;
}

@media screen and (max-width: 991px) {
  #w-node-_90533d6f-87d0-7efa-f6d4-377f474db477-1824b912 {
    order: 9999;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_90533d6f-87d0-7efa-f6d4-377f474db477-1824b912, #w-node-c9aeb089-a4cb-3e6c-930b-c523d3e84223-1824b912, #w-node-_9f220487-fe3d-775d-8b57-b2608248d8cd-1824b979 {
    justify-self: center;
  }

  #w-node-_53df67a5-edd6-af60-dd71-297e8b5af325-1824b979, #w-node-_117cb24a-25c3-4713-f83c-95d516b689fb-1824b9b9 {
    order: -9999;
  }

  #w-node-_2b9496c3-8cd9-f377-50eb-c20f8553bb54-1824b9d3 {
    justify-self: center;
  }

  #w-node-_8972abef-f6f2-a3ff-ba38-0863e6582a7c-1824b9d8 {
    order: -9999;
  }
}


