.testmyevent {
    font-size: 30px;
    background-color: pink;
    color: blue;
}

.cal_top {
    background-color: lightblue;
}

.cal_rappels {
    background-color: lightblue;
}

.cal_left_side {
    color: #00008B;
}

.cal_day_column {
    background-color: pink;
    border-width: 2px;
    border-color: #00008B;
}

.cal_day_column2 {
    background-color: blue;
    border-width: 2px;
    border-color: #00008B;
}

.cal_day_column.today {
    background-color: lightblue;
}