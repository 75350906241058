.register {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.form {
    margin-top: 40px;
    align-self: center;
    border: 1px solid #24388a70;
    width: fit-content;
    padding: 40px 60px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-block {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
}

.label {
    font-size: 20px;
    font-weight: 600;
    opacity: 75%;
}

input {
    font-family: inherit;
    font-size: medium;
    width: 280px;
    height: 38px;
    border: 1px solid #24398A;
    border-radius: 10px;
    padding: 0 10px;
}

.submit {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    font-size: 24px;
    font-weight: 700;
    color: #FFF;
    border: none;
    background-color: #24398A;

}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}