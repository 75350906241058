@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

.la_body {
    min-height: 100vh;
    color: black;
    font-family: DM Sans, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

/* CSS for managing smaller sizes of screen 850-100px */
@media screen and (min-width: 1000px) {
    .la_column_section {
        max-width: 858px;
    }

    .la_column_section.large {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1000px) {
    .la_stats-model._10 {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .la_stats-model._5 {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    .la_column_section {
        min-width: 94vw;
    }
}

.text-field-8 {
    /* todo(1) fix ui */
    color: black;
}

.la_calendar-button:hover .la_information-de-la-vente {
    display: flex;
}

.la_calendar-button:not(:hover) .la_information-de-la-vente {
    display: none;
}

.la_chatbot-section._2.preprod {
    padding-top: 170px;
    display: flex;
}

.la_button-close.preprod {
    inset: 130px 40px auto auto;
}

/* todo(2) remove and put in the webflow and its export */
.la_link-page {
    width: 40px;
    height: 40px;
}

.la_holder-input-title.conversation {
    overflow-y: auto;
    overflow-x: hidden;
}

.la_holder-input-title::-webkit-scrollbar-track {
    background-color: #2e2f32;
    /* Track color */
}

.la_holder-input-title::-webkit-scrollbar-thumb {
    background-color: grey;
    /* Thumb color */
}

/* todo(2) remove and put in the webflow and its export */
.la_holder-button-ai {
    cursor: pointer;
}

/* .fc,  */
.fc .fc-button-primary {
    /* color: #ffffff;
    background-color: #3485FF !important;
    border-color: #FFFFFF !important; */
    color: #253882 !important;

    background-color: #2538820d !important;
    /* border-left: 1px solid #2538820d;
    border-top-right-radius: 99px;
    border-bottom-right-radius: 99px; */
    /* justify-content: center;
    align-items: center;
    display: flex; */
}

.fc-button-primary[disabled] {
    color: #253882 !important;
    background-color: white !important;
}

.fc-toolbar-title {
    color: white;
    font-family: Figtree, sans-serif;
    font-size: 25px;
    line-height: 30px;
}

/* .fc-button-active {
    background-color: #2538820d !important;
} */

.la_tb45 {
    color: #253883;
}

.la_text-block-46 {
    color: black;
}

.la_b2b {
    color: black;
}

.la_b2b.selected {
    color: white;
}

.la_text-block-47 {
    color: black;
}

.la_section-6 {
    color: black;
}

.la_header_logo {
    height: 25px;
}

.la_header_logo.large {
    height: 45px;
}

.dropdown-toggle {
    color: white;
    background-color: #484444;
}

.la_button-close {
    cursor: pointer;
}

.la_status-dropdown {
    background-color: #253882;
}

.la_select-field {
    background-color: #253882;
}

.la_la_filter-dropdown option {
    color: white;
}

.la_status-dropdown option {
    color: white;
}

.la_select-field option {
    color: white;
}

/* todo(3) replace all ffffff1a with 484444*/
.dropdown-toggle option {
    color: white;
    background-color: #484444;
}